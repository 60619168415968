import styled, { css } from 'styled-components'

export const ApplicationSection = styled.div`
  padding: 65px 0 110px 0;
  background-color: #182940;
`
export const MainTitle = styled.div`
  text-align: center;
  color: white;
  font-size: 36px;
  margin-bottom: 60px;
`
export const TabWrap = styled.div`
  width: 100%;
`

export const TabHeader = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #2b415e;
`

export const TabContent = styled.div`
  margin-top: 60px;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-around;
`

export const TabContentOne = styled.div`
  img {
    width: 100%;
    height: 100%;
  }
`
export const TabContentTwo = styled.div`
  width: 720px;
  height: 400px;
  margin: 0 40px;
  position: relative;
`

export const TabContentTwoContent = styled.div`
  width: 720px;
  height: 400px;
  color: white;
  position: absolute;
  top: 0;
  background: rgba(253, 92, 31, 0.7);
  h3 {
    padding: 45px;
    color: white;
  }
  p {
    margin-left: 45px;
    font-size: 14px;
    margin-bottom: 10px;
    width: 580px;
  }
`

export const TabTitle = styled.span`
  ${({ theme: { color } }) => css`
    display: inline-block;
    margin: 0 80px;
    padding: 18px 10px;
    font-size: 16px;
    line-height: 20px;
    color: white;
    ${p =>
      p.active &&
      css`
        border-bottom: 3px solid ${color.secondary};
      `}
  `}
`
