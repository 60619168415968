import React from 'react'
import intl from 'react-intl-universal'
import { SolutionBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, Wrapper, H3 } from '@raysync/common/components'
import Layout from '../../components/layout'

import { ArchSection } from './atoms'
import {
  ApplicationSection,
  MainTitle,
  TabWrap,
  TabHeader,
  TabContent,
  TabTitle,
  TabContentOne,
  TabContentTwo,
  TabContentTwoContent,
} from './hybrid-cloud.atoms'

import Feature1 from '@raysync/common/components/svg/network/enterprise-interconnection/enterprise-interconnection-feature1'
import Feature2 from '@raysync/common/components/svg/network/enterprise-interconnection/enterprise-interconnection-feature2'
import Feature3 from '@raysync/common/components/svg/network/enterprise-interconnection/enterprise-interconnection-feature3'
import Feature4 from '@raysync/common/components/svg/network/enterprise-interconnection/enterprise-interconnection-feature4'

import Banner from '@raysync/common/components/svg/network/hybrid-cloud/banner'

import Framework from '../../images/network/hybrid-cloud/framework.png'
import img1 from '../../images/network/hybrid-cloud/image-1.png'
import img2 from '../../images/network/hybrid-cloud/image-2.png'
import img3 from '../../images/network/hybrid-cloud/image-4.png'
import img4 from '../../images/network/hybrid-cloud/image-3.png'

class HybridCloud extends React.PureComponent {
  state = {
    //方案优势列表
    featureList: [
      {
        title: intl.get('network6.feature.item1.title'),
        desc: intl.get('network6.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('network6.feature.item2.title'),
        desc: intl.get('network6.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('network6.feature.item3.title'),
        desc: intl.get('network6.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('network6.feature.item4.title'),
        desc: intl.get('network6.feature.item4.desc'),
        svg: <Feature4 />,
      },
    ],

    funcList: [
      {
        title: intl.get('network6.func.item1.title'),
        desc1: intl.get('network6.func.item1.desc1'),
        desc2: intl.get('network6.func.item1.desc2'),
        desc3: intl.get('network6.func.item1.desc3'),
      },
      {
        title: intl.get('network6.func.item2.title'),
        desc1: intl.get('network6.func.item2.desc1'),
        desc2: intl.get('network6.func.item2.desc2'),
      },
      {
        title: intl.get('network6.func.item3.title'),
        desc1: intl.get('network6.func.item3.desc1'),
        desc2: intl.get('network6.func.item3.desc2'),
        desc3: intl.get('network6.func.item3.desc3'),
        desc4: intl.get('network6.func.item3.desc4'),
        desc5: intl.get('network6.func.item3.desc5'),
        desc6: intl.get('network6.func.item3.desc6'),
      },
      {
        title: intl.get('network6.func.item4.title'),
        desc1: intl.get('network6.func.item4.desc1'),
        desc2: intl.get('network6.func.item4.desc2'),
        desc3: intl.get('network6.func.item4.desc3'),
        desc4: intl.get('network6.func.item4.desc4'),
        desc5: intl.get('network6.func.item4.desc5'),
        desc6: intl.get('network6.func.item4.desc6'),
        desc7: intl.get('network6.func.item4.desc7'),
      },
    ],
    topTabActive: 0,
    activedTitle: intl.get('network6.func.item1.title'),
    activedDesc1: intl.get('network6.func.item1.desc1'),
    activedDesc2: intl.get('network6.func.item1.desc2'),
    activedDesc3: intl.get('network6.func.item1.desc3'),
    activedDesc4: '',
    activedDesc5: '',
    activedDesc6: '',
    activedDesc7: '',
  }

  triggerTopTabActive(index) {
    this.setState({
      topTabActive: index,
      activedTitle: this.state.funcList[index].title,
      activedDesc1: this.state.funcList[index].desc1,
      activedDesc2: this.state.funcList[index].desc2,
      activedDesc3: this.state.funcList[index].desc3,
      activedDesc4: this.state.funcList[index].desc4,
      activedDesc5: this.state.funcList[index].desc5,
      activedDesc6: this.state.funcList[index].desc6,
      activedDesc7: this.state.funcList[index].desc7,
    })
  }

  render() {
    const { location } = this.props
    const {
      topTabActive,
      activedTitle,
      activedDesc1,
      activedDesc2,
      activedDesc3,
      activedDesc4,
      activedDesc5,
      activedDesc6,
      activedDesc7,
    } = this.state

    return (
      <Layout pageType='network6' location={location} scaled>
        {/* 第一屏(弹性混合云解决方案) */}
        <Swiper>
          <SolutionBanner type='network6' pic={<Banner />} />
        </Swiper>

        {/* 第二屏(为什么选择混合云架构？) */}
        <ArchSection>
          <Wrapper>
            <CommonTitle bottom={50}>{intl.get('network6.arch.title')}</CommonTitle>
            <div style={{ margin: '0 50px 0 0' }}>
              <img src={Framework} alt='feature' />
            </div>
          </Wrapper>
        </ArchSection>

        {/* 第三屏(应用场景) */}
        <ApplicationSection>
          <MainTitle>{intl.get('network6.func.title1')}</MainTitle>
          <TabWrap>
            <TabHeader>
              <TabTitle active={topTabActive === 0} onClick={this.triggerTopTabActive.bind(this, 0)}>
                {intl.get('network6.func.title2')}
              </TabTitle>
              <TabTitle active={topTabActive === 1} onClick={this.triggerTopTabActive.bind(this, 1)}>
                {intl.get('network6.func.title3')}
              </TabTitle>
              <TabTitle active={topTabActive === 2} onClick={this.triggerTopTabActive.bind(this, 2)}>
                {intl.get('network6.func.title4')}
              </TabTitle>
              <TabTitle active={topTabActive === 3} onClick={this.triggerTopTabActive.bind(this, 3)}>
                {intl.get('network6.func.title5')}
              </TabTitle>
            </TabHeader>

            <TabContent>
              <TabContentOne>
                <img
                  src={topTabActive === 0 ? img3 : topTabActive === 1 ? img1 : topTabActive === 2 ? img2 : img4}
                  alt='feature'
                />
              </TabContentOne>
              <TabContentTwo>
                <img
                  src={topTabActive === 0 ? img1 : topTabActive === 1 ? img2 : topTabActive === 2 ? img4 : img3}
                  alt='feature'
                />
                <TabContentTwoContent>
                  <div>
                    <H3>{activedTitle}</H3>
                    <p>{activedDesc1}</p>
                    <p>{activedDesc2}</p>
                    <p>{activedDesc3}</p>
                    <p>{activedDesc4}</p>
                    <p>{activedDesc5}</p>
                    <p>{activedDesc6}</p>
                    <p>{activedDesc7}</p>
                  </div>
                </TabContentTwoContent>
              </TabContentTwo>
              <TabContentOne>
                <img
                  src={topTabActive === 0 ? img2 : topTabActive === 1 ? img4 : topTabActive === 2 ? img3 : img1}
                  alt='feature'
                />
              </TabContentOne>
            </TabContent>
          </TabWrap>
        </ApplicationSection>

        {/* 第四屏(申请免费试用) */}
        <Swiper>
          <InviteBanner type='network6' />
        </Swiper>
      </Layout>
    )
  }
}

export default HybridCloud
