import React from 'react'

const SvgBanner = props => (
  <svg
    id='banner_svg__Layer_1'
    x={0}
    y={0}
    viewBox='0 0 470 400'
    xmlSpace='preserve'
    enableBackground='new 0 0 470 400'
    {...props}
  >
    <style>{'.banner_svg__st17{fill:#f3f6ff}.banner_svg__st187{fill:#012860}'}</style>
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter'
        filterUnits='userSpaceOnUse'
        x={87.97}
        y={66.23}
        width={229.25}
        height={132.4}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={87.97} y={66.23} width={229.25} height={132.4} id='banner_svg__SVGID_1_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_1_'
            filterUnits='userSpaceOnUse'
            x={87.97}
            y={66.23}
            width={229.25}
            height={132.4}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_2_'
          gradientUnits='userSpaceOnUse'
          x1={87.966}
          y1={132.424}
          x2={317.22}
          y2={132.424}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.333} stopColor='#c1c1c1' />
          <stop offset={0.639} stopColor='#909090' />
          <stop offset={0.87} stopColor='#717171' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M92.21 138.28l100.13 57.89c5.66 3.27 14.84 3.27 20.5 0l100.13-57.88c5.66-3.27 5.66-8.57 0-11.84L212.93 68.68c-5.66-3.27-14.84-3.27-20.51-.01l-100.2 57.77c-5.67 3.27-5.67 8.57-.01 11.84z'
          mask='url(#banner_svg__SVGID_1_)'
          fill='url(#banner_svg__SVGID_2_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={87.966}
      y1={132.424}
      x2={317.22}
      y2={132.424}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.404} stopColor='#f4f7ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M92.21 138.28l100.13 57.89c5.66 3.27 14.84 3.27 20.5 0l100.13-57.88c5.66-3.27 5.66-8.57 0-11.84L212.93 68.68c-5.66-3.27-14.84-3.27-20.51-.01l-100.2 57.77c-5.67 3.27-5.67 8.57-.01 11.84z'
      fill='url(#banner_svg__SVGID_3_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_2_'
        filterUnits='userSpaceOnUse'
        x={227.57}
        y={270.34}
        width={148.25}
        height={85.61}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={227.57} y={270.34} width={148.25} height={85.61} id='banner_svg__SVGID_4_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_2_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_3_'
            filterUnits='userSpaceOnUse'
            x={227.57}
            y={270.34}
            width={148.25}
            height={85.61}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_5_'
          gradientUnits='userSpaceOnUse'
          x1={227.573}
          y1={313.149}
          x2={375.818}
          y2={313.149}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M230.32 316.94l64.75 37.43c3.66 2.12 9.59 2.12 13.25 0l64.75-37.43c3.66-2.12 3.66-5.54 0-7.66l-64.69-37.35c-3.66-2.11-9.6-2.12-13.26 0l-64.79 37.36c-3.67 2.1-3.67 5.53-.01 7.65z'
          mask='url(#banner_svg__SVGID_4_)'
          fill='url(#banner_svg__SVGID_5_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={227.573}
      y1={313.149}
      x2={375.818}
      y2={313.149}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M230.32 316.94l64.75 37.43c3.66 2.12 9.59 2.12 13.25 0l64.75-37.43c3.66-2.12 3.66-5.54 0-7.66l-64.69-37.35c-3.66-2.11-9.6-2.12-13.26 0l-64.79 37.36c-3.67 2.1-3.67 5.53-.01 7.65z'
      fill='url(#banner_svg__SVGID_6_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_4_'
        filterUnits='userSpaceOnUse'
        x={86.32}
        y={202.23}
        width={77.34}
        height={44.66}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={86.32} y={202.23} width={77.34} height={44.66} id='banner_svg__SVGID_7_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_4_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_5_'
            filterUnits='userSpaceOnUse'
            x={86.32}
            y={202.23}
            width={77.34}
            height={44.66}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_8_'
          gradientUnits='userSpaceOnUse'
          x1={86.323}
          y1={224.559}
          x2={163.66}
          y2={224.559}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M87.75 226.53l33.78 19.53c1.91 1.1 5.01 1.1 6.91 0l33.78-19.53c1.91-1.1 1.91-2.89 0-4l-33.75-19.49c-1.91-1.1-5.01-1.1-6.92 0l-33.8 19.49c-1.9 1.11-1.9 2.9 0 4z'
          mask='url(#banner_svg__SVGID_7_)'
          fill='url(#banner_svg__SVGID_8_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_9_'
      gradientUnits='userSpaceOnUse'
      x1={86.323}
      y1={224.559}
      x2={163.66}
      y2={224.559}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M87.75 226.53l33.78 19.53c1.91 1.1 5.01 1.1 6.91 0l33.78-19.53c1.91-1.1 1.91-2.89 0-4l-33.75-19.49c-1.91-1.1-5.01-1.1-6.92 0l-33.8 19.49c-1.9 1.11-1.9 2.9 0 4z'
      fill='url(#banner_svg__SVGID_9_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_6_'
        filterUnits='userSpaceOnUse'
        x={302.36}
        y={117.38}
        width={77.34}
        height={44.66}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={302.36} y={117.38} width={77.34} height={44.66} id='banner_svg__SVGID_10_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_6_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_7_'
            filterUnits='userSpaceOnUse'
            x={302.36}
            y={117.38}
            width={77.34}
            height={44.66}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_11_'
          gradientUnits='userSpaceOnUse'
          x1={302.355}
          y1={139.708}
          x2={379.692}
          y2={139.708}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M303.79 141.68l33.78 19.53c1.91 1.1 5.01 1.1 6.91 0l33.78-19.53c1.91-1.1 1.91-2.89 0-4l-33.75-19.49c-1.91-1.1-5.01-1.1-6.92 0l-33.8 19.49c-1.91 1.11-1.91 2.9 0 4z'
          mask='url(#banner_svg__SVGID_10_)'
          fill='url(#banner_svg__SVGID_11_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_12_'
      gradientUnits='userSpaceOnUse'
      x1={302.355}
      y1={139.708}
      x2={379.692}
      y2={139.708}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M303.79 141.68l33.78 19.53c1.91 1.1 5.01 1.1 6.91 0l33.78-19.53c1.91-1.1 1.91-2.89 0-4l-33.75-19.49c-1.91-1.1-5.01-1.1-6.92 0l-33.8 19.49c-1.91 1.11-1.91 2.9 0 4z'
      fill='url(#banner_svg__SVGID_12_)'
    />
    <path fill='#d4dffc' d='M174.71 307.78l-84.29-48.67 1.09-1.9 83.2 48.04 124.99-72.2 1.09 1.9z' />
    <path className='banner_svg__st17' d='M124.6 187.64l-61.56 35.54-61.56-35.56 61.04-35.24.52-.29z' />
    <linearGradient
      id='banner_svg__SVGID_13_'
      gradientUnits='userSpaceOnUse'
      x1={1.48}
      y1={215.549}
      x2={63.039}
      y2={215.549}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_13_)' d='M1.48 187.62v20.31l61.56 35.54v-20.29z' />
    <linearGradient
      id='banner_svg__SVGID_14_'
      gradientUnits='userSpaceOnUse'
      x1={63.039}
      y1={215.555}
      x2={124.597}
      y2={215.555}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_14_)' d='M63.04 243.47l61.56-35.53v-20.3l-61.56 35.54z' />
    <linearGradient
      id='banner_svg__SVGID_15_'
      gradientUnits='userSpaceOnUse'
      x1={42.835}
      y1={222.217}
      x2={46.165}
      y2={222.217}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M43.08 220.7c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25.48-.69.24-1.94-.54-2.77-.78-.84-1.8-.95-2.29-.26z'
      fill='url(#banner_svg__SVGID_15_)'
    />
    <linearGradient
      id='banner_svg__SVGID_16_'
      gradientUnits='userSpaceOnUse'
      x1={48.183}
      y1={225.442}
      x2={51.513}
      y2={225.442}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M48.43 223.93c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25.48-.69.24-1.94-.54-2.77-.78-.84-1.81-.96-2.29-.26z'
      fill='url(#banner_svg__SVGID_16_)'
    />
    <linearGradient
      id='banner_svg__SVGID_17_'
      gradientUnits='userSpaceOnUse'
      x1={53.53}
      y1={228.558}
      x2={56.86}
      y2={228.558}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M53.78 227.04c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25s.24-1.94-.54-2.77c-.78-.84-1.81-.95-2.29-.26z'
      fill='url(#banner_svg__SVGID_17_)'
    />
    <linearGradient
      id='banner_svg__SVGID_18_'
      gradientUnits='userSpaceOnUse'
      x1={11.188}
      y1={205.498}
      x2={18.166}
      y2={205.498}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M11.19 202.72v1.75c0 .22.12.42.3.53l6.2 3.58c.21.12.47-.03.47-.27v-1.73c0-.26-.14-.5-.37-.63l-6.1-3.52c-.22-.14-.5.03-.5.29z'
      fill='url(#banner_svg__SVGID_18_)'
    />
    <linearGradient
      id='banner_svg__SVGID_19_'
      gradientUnits='userSpaceOnUse'
      x1={115.805}
      y1={201.583}
      x2={119.135}
      y2={201.583}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M118.89 200.07c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.8-.96 2.29-.26z'
      fill='url(#banner_svg__SVGID_19_)'
    />
    <linearGradient
      id='banner_svg__SVGID_20_'
      gradientUnits='userSpaceOnUse'
      x1={110.457}
      y1={204.809}
      x2={113.787}
      y2={204.809}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M113.54 203.29c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.81-.95 2.29-.26z'
      fill='url(#banner_svg__SVGID_20_)'
    />
    <linearGradient
      id='banner_svg__SVGID_21_'
      gradientUnits='userSpaceOnUse'
      x1={105.11}
      y1={207.924}
      x2={108.44}
      y2={207.924}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M108.19 206.41c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.81-.96 2.29-.26z'
      fill='url(#banner_svg__SVGID_21_)'
    />
    <linearGradient
      id='banner_svg__SVGID_22_'
      gradientUnits='userSpaceOnUse'
      x1={72.746}
      y1={225.746}
      x2={79.723}
      y2={225.746}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M79.72 222.96v1.75c0 .22-.12.42-.3.53l-6.2 3.58a.313.313 0 01-.47-.27v-1.73c0-.26.14-.5.37-.63l6.1-3.52c.22-.13.5.03.5.29z'
      fill='url(#banner_svg__SVGID_22_)'
    />
    <linearGradient
      id='banner_svg__SVGID_23_'
      gradientUnits='userSpaceOnUse'
      x1={6.613}
      y1={186.892}
      x2={10.286}
      y2={186.892}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M10.29 184.56v3.41c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25s1.84-.56 1.84-1.25z'
      fill='url(#banner_svg__SVGID_23_)'
    />
    <linearGradient
      id='banner_svg__SVGID_24_'
      gradientUnits='userSpaceOnUse'
      x1={6.613}
      y1={184.564}
      x2={10.286}
      y2={184.564}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M10.29 184.56c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25s.82-1.25 1.84-1.25c1.02.01 1.84.57 1.84 1.25z'
      fill='url(#banner_svg__SVGID_24_)'
    />
    <linearGradient
      id='banner_svg__SVGID_25_'
      gradientUnits='userSpaceOnUse'
      x1={61.212}
      y1={155.248}
      x2={64.885}
      y2={155.248}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M64.89 152.92v3.41c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25 1.01 0 1.84-.56 1.84-1.25z'
      fill='url(#banner_svg__SVGID_25_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_8_'
        filterUnits='userSpaceOnUse'
        x={62.52}
        y={152.09}
        width={62.08}
        height={71.09}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={62.52} y={152.09} width={62.08} height={71.09} id='banner_svg__SVGID_26_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_8_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_9_'
            filterUnits='userSpaceOnUse'
            x={62.52}
            y={152.09}
            width={62.08}
            height={71.09}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_27_'
          gradientUnits='userSpaceOnUse'
          x1={62.522}
          y1={187.631}
          x2={124.598}
          y2={187.631}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M63.04 223.18s11.09-41.97-.52-70.79l.52-.3 61.56 35.55-61.56 35.54z'
          mask='url(#banner_svg__SVGID_26_)'
          fill='url(#banner_svg__SVGID_27_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_28_'
      gradientUnits='userSpaceOnUse'
      x1={62.522}
      y1={187.631}
      x2={124.598}
      y2={187.631}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.547} stopColor='#daeaff' />
      <stop offset={0.999} stopColor='#d4e7ff' />
    </linearGradient>
    <path d='M63.04 223.18s11.09-41.97-.52-70.79l.52-.3 61.56 35.55-61.56 35.54z' fill='url(#banner_svg__SVGID_28_)' />
    <linearGradient
      id='banner_svg__SVGID_29_'
      gradientUnits='userSpaceOnUse'
      x1={61.212}
      y1={152.92}
      x2={64.885}
      y2={152.92}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M64.89 152.92c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25s.82-1.25 1.84-1.25c1.01 0 1.84.56 1.84 1.25z'
      fill='url(#banner_svg__SVGID_29_)'
    />
    <linearGradient
      id='banner_svg__SVGID_30_'
      gradientUnits='userSpaceOnUse'
      x1={117.298}
      y1={186.434}
      x2={120.971}
      y2={186.434}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M120.97 184.11v3.41c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25 1.02-.01 1.84-.57 1.84-1.25z'
      fill='url(#banner_svg__SVGID_30_)'
    />
    <linearGradient
      id='banner_svg__SVGID_31_'
      gradientUnits='userSpaceOnUse'
      x1={117.298}
      y1={184.106}
      x2={120.971}
      y2={184.106}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M120.97 184.11c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25s.82-1.25 1.84-1.25 1.84.56 1.84 1.25z'
      fill='url(#banner_svg__SVGID_31_)'
    />
    <linearGradient
      id='banner_svg__SVGID_32_'
      gradientUnits='userSpaceOnUse'
      x1={61.202}
      y1={217.92}
      x2={64.875}
      y2={217.92}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M64.88 215.59V219c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25 1.01 0 1.84-.56 1.84-1.25z'
      fill='url(#banner_svg__SVGID_32_)'
    />
    <linearGradient
      id='banner_svg__SVGID_33_'
      gradientUnits='userSpaceOnUse'
      x1={61.202}
      y1={215.592}
      x2={64.875}
      y2={215.592}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M64.88 215.59c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25s.82-1.25 1.84-1.25c1.01 0 1.84.56 1.84 1.25z'
      fill='url(#banner_svg__SVGID_33_)'
    />
    <linearGradient
      id='banner_svg__SVGID_34_'
      gradientUnits='userSpaceOnUse'
      x1={1.514}
      y1={235.868}
      x2={63.073}
      y2={235.868}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_34_)' d='M1.51 207.94v20.31l61.56 35.54V243.5z' />
    <linearGradient
      id='banner_svg__SVGID_35_'
      gradientUnits='userSpaceOnUse'
      x1={63.073}
      y1={235.874}
      x2={124.632}
      y2={235.874}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_35_)' d='M63.07 263.79l61.56-35.54v-20.3L63.07 243.5z' />
    <linearGradient
      id='banner_svg__SVGID_36_'
      gradientUnits='userSpaceOnUse'
      x1={42.87}
      y1={242.536}
      x2={46.2}
      y2={242.536}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M43.12 241.02c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25.48-.69.24-1.94-.54-2.77-.78-.84-1.81-.95-2.29-.26z'
      fill='url(#banner_svg__SVGID_36_)'
    />
    <linearGradient
      id='banner_svg__SVGID_37_'
      gradientUnits='userSpaceOnUse'
      x1={48.217}
      y1={245.761}
      x2={51.547}
      y2={245.761}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M48.46 244.25c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25.48-.69.24-1.94-.54-2.77-.78-.84-1.8-.96-2.29-.26z'
      fill='url(#banner_svg__SVGID_37_)'
    />
    <linearGradient
      id='banner_svg__SVGID_38_'
      gradientUnits='userSpaceOnUse'
      x1={53.564}
      y1={248.877}
      x2={56.894}
      y2={248.877}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M53.81 247.36c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25.48-.69.24-1.94-.54-2.77-.78-.84-1.81-.95-2.29-.26z'
      fill='url(#banner_svg__SVGID_38_)'
    />
    <path
      className='banner_svg__st17'
      d='M11.22 223.03v1.75c0 .22.12.42.3.53l6.2 3.58c.21.12.47-.03.47-.27v-1.73c0-.26-.14-.5-.37-.63l-6.1-3.52c-.22-.13-.5.03-.5.29z'
    />
    <linearGradient
      id='banner_svg__SVGID_39_'
      gradientUnits='userSpaceOnUse'
      x1={115.839}
      y1={221.902}
      x2={119.169}
      y2={221.902}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M118.92 220.39c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.81-.96 2.29-.26z'
      fill='url(#banner_svg__SVGID_39_)'
    />
    <linearGradient
      id='banner_svg__SVGID_40_'
      gradientUnits='userSpaceOnUse'
      x1={110.491}
      y1={225.127}
      x2={113.822}
      y2={225.127}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M113.57 223.61c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.81-.95 2.29-.26z'
      fill='url(#banner_svg__SVGID_40_)'
    />
    <linearGradient
      id='banner_svg__SVGID_41_'
      gradientUnits='userSpaceOnUse'
      x1={105.144}
      y1={228.243}
      x2={108.475}
      y2={228.243}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M108.23 226.73c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.8-.96 2.29-.26z'
      fill='url(#banner_svg__SVGID_41_)'
    />
    <path
      className='banner_svg__st17'
      d='M79.76 243.28v1.75c0 .22-.12.42-.3.53l-6.2 3.58a.313.313 0 01-.47-.27v-1.73c0-.26.14-.5.37-.63l6.1-3.52c.22-.13.5.03.5.29z'
    />
    <linearGradient
      id='banner_svg__SVGID_42_'
      gradientUnits='userSpaceOnUse'
      x1={1.481}
      y1={256.158}
      x2={63.04}
      y2={256.158}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_42_)' d='M1.48 228.23v20.31l61.56 35.54v-20.29z' />
    <linearGradient
      id='banner_svg__SVGID_43_'
      gradientUnits='userSpaceOnUse'
      x1={63.04}
      y1={256.163}
      x2={124.598}
      y2={256.163}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_43_)' d='M63.04 284.08l61.56-35.54v-20.3l-61.56 35.55z' />
    <linearGradient
      id='banner_svg__SVGID_44_'
      gradientUnits='userSpaceOnUse'
      x1={42.836}
      y1={262.825}
      x2={46.166}
      y2={262.825}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M43.08 261.31c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25.48-.69.24-1.94-.54-2.77-.78-.84-1.8-.95-2.29-.26z'
      fill='url(#banner_svg__SVGID_44_)'
    />
    <linearGradient
      id='banner_svg__SVGID_45_'
      gradientUnits='userSpaceOnUse'
      x1={48.184}
      y1={266.051}
      x2={51.514}
      y2={266.051}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M48.43 264.54c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25.48-.69.24-1.94-.54-2.77-.78-.84-1.81-.96-2.29-.26z'
      fill='url(#banner_svg__SVGID_45_)'
    />
    <linearGradient
      id='banner_svg__SVGID_46_'
      gradientUnits='userSpaceOnUse'
      x1={53.531}
      y1={269.166}
      x2={56.861}
      y2={269.166}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M53.78 267.65c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25.48-.69.24-1.94-.54-2.77-.78-.84-1.81-.95-2.29-.26z'
      fill='url(#banner_svg__SVGID_46_)'
    />
    <linearGradient
      id='banner_svg__SVGID_47_'
      gradientUnits='userSpaceOnUse'
      x1={11.189}
      y1={246.107}
      x2={18.167}
      y2={246.107}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M11.19 243.32v1.75c0 .22.12.42.3.53l6.2 3.58c.21.12.47-.03.47-.27v-1.73c0-.26-.14-.5-.37-.63l-6.1-3.52c-.22-.13-.5.03-.5.29z'
      fill='url(#banner_svg__SVGID_47_)'
    />
    <linearGradient
      id='banner_svg__SVGID_48_'
      gradientUnits='userSpaceOnUse'
      x1={115.805}
      y1={242.191}
      x2={119.135}
      y2={242.191}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M118.89 240.68c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.8-.96 2.29-.26z'
      fill='url(#banner_svg__SVGID_48_)'
    />
    <linearGradient
      id='banner_svg__SVGID_49_'
      gradientUnits='userSpaceOnUse'
      x1={110.458}
      y1={245.417}
      x2={113.788}
      y2={245.417}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M113.54 243.9c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.81-.95 2.29-.26z'
      fill='url(#banner_svg__SVGID_49_)'
    />
    <linearGradient
      id='banner_svg__SVGID_50_'
      gradientUnits='userSpaceOnUse'
      x1={105.111}
      y1={248.532}
      x2={108.441}
      y2={248.532}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M108.19 247.02c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.81-.96 2.29-.26z'
      fill='url(#banner_svg__SVGID_50_)'
    />
    <linearGradient
      id='banner_svg__SVGID_51_'
      gradientUnits='userSpaceOnUse'
      x1={72.746}
      y1={266.355}
      x2={79.724}
      y2={266.355}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M79.72 263.57v1.75c0 .22-.12.42-.3.53l-6.2 3.58a.313.313 0 01-.47-.27v-1.73c0-.26.14-.5.37-.63l6.1-3.52c.22-.13.5.03.5.29z'
      fill='url(#banner_svg__SVGID_51_)'
    />
    <path className='banner_svg__st17' d='M213.77 280.88l-61.56 35.54-61.56-35.55 61.04-35.25.52-.29z' />
    <linearGradient
      id='banner_svg__SVGID_52_'
      gradientUnits='userSpaceOnUse'
      x1={90.648}
      y1={308.79}
      x2={152.207}
      y2={308.79}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_52_)' d='M90.65 280.87v20.3l61.56 35.54v-20.29z' />
    <linearGradient
      id='banner_svg__SVGID_53_'
      gradientUnits='userSpaceOnUse'
      x1={152.207}
      y1={308.796}
      x2={213.766}
      y2={308.796}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_53_)' d='M152.21 336.71l61.56-35.53v-20.3l-61.56 35.54z' />
    <path
      className='banner_svg__st17'
      d='M132.25 313.94c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25s.24-1.94-.54-2.77c-.78-.84-1.81-.95-2.29-.26zM137.6 317.17c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25s.24-1.94-.54-2.77c-.78-.84-1.81-.96-2.29-.26zM142.95 320.28c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25s.24-1.94-.54-2.77c-.78-.84-1.81-.95-2.29-.26zM100.36 295.96v1.75c0 .22.12.42.3.53l6.2 3.58c.21.12.47-.03.47-.27v-1.73c0-.26-.14-.5-.37-.63l-6.1-3.52c-.22-.14-.5.03-.5.29zM208.05 293.31c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25s-.24-1.94.54-2.77c.78-.84 1.81-.96 2.29-.26zM202.71 296.54c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25s-.24-1.94.54-2.77c.78-.85 1.8-.96 2.29-.26zM197.36 299.65c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.81-.95 2.29-.26zM168.89 316.21v1.75c0 .22-.12.42-.3.53l-6.2 3.58a.313.313 0 01-.47-.27v-1.73c0-.26.14-.5.37-.63l6.1-3.52c.22-.14.5.02.5.29z'
    />
    <linearGradient
      id='banner_svg__SVGID_54_'
      gradientUnits='userSpaceOnUse'
      x1={95.781}
      y1={280.133}
      x2={99.454}
      y2={280.133}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M99.45 277.8v3.41c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25s1.84-.56 1.84-1.25z'
      fill='url(#banner_svg__SVGID_54_)'
    />
    <linearGradient
      id='banner_svg__SVGID_55_'
      gradientUnits='userSpaceOnUse'
      x1={95.781}
      y1={277.805}
      x2={99.454}
      y2={277.805}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M99.45 277.8c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25s.82-1.25 1.84-1.25c1.02.01 1.84.57 1.84 1.25z'
      fill='url(#banner_svg__SVGID_55_)'
    />
    <linearGradient
      id='banner_svg__SVGID_56_'
      gradientUnits='userSpaceOnUse'
      x1={150.38}
      y1={248.489}
      x2={154.053}
      y2={248.489}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M154.05 246.16v3.41c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25s1.84-.56 1.84-1.25z'
      fill='url(#banner_svg__SVGID_56_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_10_'
        filterUnits='userSpaceOnUse'
        x={151.69}
        y={245.33}
        width={62.08}
        height={71.09}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={151.69} y={245.33} width={62.08} height={71.09} id='banner_svg__SVGID_57_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_10_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_11_'
            filterUnits='userSpaceOnUse'
            x={151.69}
            y={245.33}
            width={62.08}
            height={71.09}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_58_'
          gradientUnits='userSpaceOnUse'
          x1={151.69}
          y1={280.872}
          x2={213.766}
          y2={280.872}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M152.21 316.42s11.09-41.97-.52-70.79l.52-.3 61.56 35.55-61.56 35.54z'
          mask='url(#banner_svg__SVGID_57_)'
          fill='url(#banner_svg__SVGID_58_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_59_'
      gradientUnits='userSpaceOnUse'
      x1={151.69}
      y1={280.872}
      x2={213.766}
      y2={280.872}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.547} stopColor='#daeaff' />
      <stop offset={0.999} stopColor='#d4e7ff' />
    </linearGradient>
    <path d='M152.21 316.42s11.09-41.97-.52-70.79l.52-.3 61.56 35.55-61.56 35.54z' fill='url(#banner_svg__SVGID_59_)' />
    <linearGradient
      id='banner_svg__SVGID_60_'
      gradientUnits='userSpaceOnUse'
      x1={150.38}
      y1={246.161}
      x2={154.053}
      y2={246.161}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M154.05 246.16c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25s.82-1.25 1.84-1.25 1.84.56 1.84 1.25z'
      fill='url(#banner_svg__SVGID_60_)'
    />
    <linearGradient
      id='banner_svg__SVGID_61_'
      gradientUnits='userSpaceOnUse'
      x1={206.466}
      y1={279.675}
      x2={210.139}
      y2={279.675}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M210.14 277.35v3.41c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25 1.02-.01 1.84-.57 1.84-1.25z'
      fill='url(#banner_svg__SVGID_61_)'
    />
    <linearGradient
      id='banner_svg__SVGID_62_'
      gradientUnits='userSpaceOnUse'
      x1={206.466}
      y1={277.347}
      x2={210.139}
      y2={277.347}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M210.14 277.35c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25s.82-1.25 1.84-1.25 1.84.56 1.84 1.25z'
      fill='url(#banner_svg__SVGID_62_)'
    />
    <linearGradient
      id='banner_svg__SVGID_63_'
      gradientUnits='userSpaceOnUse'
      x1={150.37}
      y1={311.161}
      x2={154.043}
      y2={311.161}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M154.04 308.83v3.41c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25s1.84-.56 1.84-1.25z'
      fill='url(#banner_svg__SVGID_63_)'
    />
    <linearGradient
      id='banner_svg__SVGID_64_'
      gradientUnits='userSpaceOnUse'
      x1={150.37}
      y1={308.833}
      x2={154.043}
      y2={308.833}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M154.04 308.83c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25s.82-1.25 1.84-1.25c1.02.01 1.84.56 1.84 1.25z'
      fill='url(#banner_svg__SVGID_64_)'
    />
    <path className='banner_svg__st17' d='M326.94 240.17l-61.56 35.54-61.56-35.56 61.05-35.24.51-.3z' />
    <linearGradient
      id='banner_svg__SVGID_65_'
      gradientUnits='userSpaceOnUse'
      x1={203.823}
      y1={268.079}
      x2={265.383}
      y2={268.079}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_65_)' d='M203.82 240.15v20.31L265.38 296v-20.29z' />
    <linearGradient
      id='banner_svg__SVGID_66_'
      gradientUnits='userSpaceOnUse'
      x1={265.383}
      y1={268.084}
      x2={326.941}
      y2={268.084}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_66_)' d='M265.38 296l61.56-35.53v-20.3l-61.56 35.54z' />
    <linearGradient
      id='banner_svg__SVGID_67_'
      gradientUnits='userSpaceOnUse'
      x1={245.179}
      y1={274.746}
      x2={248.509}
      y2={274.746}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M245.43 273.23c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25s.24-1.94-.54-2.77c-.78-.84-1.81-.95-2.29-.26z'
      fill='url(#banner_svg__SVGID_67_)'
    />
    <linearGradient
      id='banner_svg__SVGID_68_'
      gradientUnits='userSpaceOnUse'
      x1={250.526}
      y1={277.972}
      x2={253.856}
      y2={277.972}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M250.77 276.46c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25.48-.69.24-1.94-.54-2.77-.78-.84-1.8-.96-2.29-.26z'
      fill='url(#banner_svg__SVGID_68_)'
    />
    <linearGradient
      id='banner_svg__SVGID_69_'
      gradientUnits='userSpaceOnUse'
      x1={255.873}
      y1={281.087}
      x2={259.203}
      y2={281.087}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M256.12 279.57c-.22.32-.29.74-.23 1.2.05.37.19.75.41 1.11.1.17.22.32.36.47.78.84 1.81.95 2.29.25.48-.69.24-1.94-.54-2.77-.78-.84-1.81-.95-2.29-.26z'
      fill='url(#banner_svg__SVGID_69_)'
    />
    <linearGradient
      id='banner_svg__SVGID_70_'
      gradientUnits='userSpaceOnUse'
      x1={213.532}
      y1={258.028}
      x2={220.51}
      y2={258.028}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M213.53 255.25V257c0 .22.12.42.3.53l6.2 3.58c.21.12.47-.03.47-.27v-1.73c0-.26-.14-.5-.37-.63l-6.1-3.52c-.22-.14-.5.03-.5.29z'
      fill='url(#banner_svg__SVGID_70_)'
    />
    <linearGradient
      id='banner_svg__SVGID_71_'
      gradientUnits='userSpaceOnUse'
      x1={318.148}
      y1={254.112}
      x2={321.478}
      y2={254.112}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M321.23 252.6c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.81-.96 2.29-.26z'
      fill='url(#banner_svg__SVGID_71_)'
    />
    <linearGradient
      id='banner_svg__SVGID_72_'
      gradientUnits='userSpaceOnUse'
      x1={312.801}
      y1={257.338}
      x2={316.131}
      y2={257.338}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M315.88 255.82c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.81-.95 2.29-.26z'
      fill='url(#banner_svg__SVGID_72_)'
    />
    <linearGradient
      id='banner_svg__SVGID_73_'
      gradientUnits='userSpaceOnUse'
      x1={307.454}
      y1={260.453}
      x2={310.784}
      y2={260.453}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M310.54 258.94c.22.32.29.74.23 1.2-.05.37-.19.75-.41 1.11-.1.17-.22.32-.36.47-.78.84-1.81.95-2.29.25-.48-.69-.24-1.94.54-2.77.78-.84 1.8-.96 2.29-.26z'
      fill='url(#banner_svg__SVGID_73_)'
    />
    <linearGradient
      id='banner_svg__SVGID_74_'
      gradientUnits='userSpaceOnUse'
      x1={275.089}
      y1={278.276}
      x2={282.067}
      y2={278.276}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M282.07 275.49v1.75c0 .22-.12.42-.3.53l-6.2 3.58a.313.313 0 01-.47-.27v-1.73c0-.26.14-.5.37-.63l6.1-3.52c.22-.13.5.03.5.29z'
      fill='url(#banner_svg__SVGID_74_)'
    />
    <linearGradient
      id='banner_svg__SVGID_75_'
      gradientUnits='userSpaceOnUse'
      x1={208.957}
      y1={239.421}
      x2={212.63}
      y2={239.421}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M212.63 237.09v3.41c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25s1.84-.56 1.84-1.25z'
      fill='url(#banner_svg__SVGID_75_)'
    />
    <linearGradient
      id='banner_svg__SVGID_76_'
      gradientUnits='userSpaceOnUse'
      x1={208.957}
      y1={237.093}
      x2={212.63}
      y2={237.093}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M212.63 237.09c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25s.82-1.25 1.84-1.25c1.02.01 1.84.57 1.84 1.25z'
      fill='url(#banner_svg__SVGID_76_)'
    />
    <linearGradient
      id='banner_svg__SVGID_77_'
      gradientUnits='userSpaceOnUse'
      x1={263.556}
      y1={207.777}
      x2={267.229}
      y2={207.777}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M267.23 205.45v3.41c0 .69-.82 1.25-1.84 1.25s-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25s1.84-.56 1.84-1.25z'
      fill='url(#banner_svg__SVGID_77_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_12_'
        filterUnits='userSpaceOnUse'
        x={264.87}
        y={204.61}
        width={62.08}
        height={71.09}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={264.87} y={204.61} width={62.08} height={71.09} id='banner_svg__SVGID_78_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_12_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_13_'
            filterUnits='userSpaceOnUse'
            x={264.87}
            y={204.61}
            width={62.08}
            height={71.09}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_79_'
          gradientUnits='userSpaceOnUse'
          x1={264.865}
          y1={240.16}
          x2={326.941}
          y2={240.16}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M265.38 275.71s11.09-41.97-.52-70.79l.52-.3 61.56 35.55-61.56 35.54z'
          mask='url(#banner_svg__SVGID_78_)'
          fill='url(#banner_svg__SVGID_79_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_80_'
      gradientUnits='userSpaceOnUse'
      x1={264.865}
      y1={240.16}
      x2={326.941}
      y2={240.16}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.547} stopColor='#daeaff' />
      <stop offset={0.999} stopColor='#d4e7ff' />
    </linearGradient>
    <path d='M265.38 275.71s11.09-41.97-.52-70.79l.52-.3 61.56 35.55-61.56 35.54z' fill='url(#banner_svg__SVGID_80_)' />
    <linearGradient
      id='banner_svg__SVGID_81_'
      gradientUnits='userSpaceOnUse'
      x1={263.556}
      y1={205.449}
      x2={267.229}
      y2={205.449}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M267.23 205.45c0 .69-.82 1.25-1.84 1.25s-1.84-.56-1.84-1.25.82-1.25 1.84-1.25 1.84.56 1.84 1.25z'
      fill='url(#banner_svg__SVGID_81_)'
    />
    <linearGradient
      id='banner_svg__SVGID_82_'
      gradientUnits='userSpaceOnUse'
      x1={319.642}
      y1={238.964}
      x2={323.315}
      y2={238.964}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M323.31 236.64v3.41c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25 1.02-.01 1.84-.57 1.84-1.25z'
      fill='url(#banner_svg__SVGID_82_)'
    />
    <linearGradient
      id='banner_svg__SVGID_83_'
      gradientUnits='userSpaceOnUse'
      x1={319.642}
      y1={236.636}
      x2={323.315}
      y2={236.636}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M323.31 236.64c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25s.82-1.25 1.84-1.25 1.84.56 1.84 1.25z'
      fill='url(#banner_svg__SVGID_83_)'
    />
    <linearGradient
      id='banner_svg__SVGID_84_'
      gradientUnits='userSpaceOnUse'
      x1={263.546}
      y1={270.449}
      x2={267.219}
      y2={270.449}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M267.22 268.12v3.41c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25v-3.41c0 .69.82 1.25 1.84 1.25s1.84-.56 1.84-1.25z'
      fill='url(#banner_svg__SVGID_84_)'
    />
    <linearGradient
      id='banner_svg__SVGID_85_'
      gradientUnits='userSpaceOnUse'
      x1={263.546}
      y1={268.121}
      x2={267.219}
      y2={268.121}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M267.22 268.12c0 .69-.82 1.25-1.84 1.25-1.01 0-1.84-.56-1.84-1.25s.82-1.25 1.84-1.25 1.84.56 1.84 1.25z'
      fill='url(#banner_svg__SVGID_85_)'
    />
    <path
      className='banner_svg__st17'
      d='M314.21 177.59l-13.96 8.05-13.93-8.04-13.97 8.05v-24.18l41.87.01-13.96 8.06zM356.08 201.77l-41.86 24.17-13.96-8.07 27.91-16.11-6.69-3.86 13.66-8.22h.01z'
    />
    <linearGradient
      id='banner_svg__SVGID_86_'
      gradientUnits='userSpaceOnUse'
      x1={301.946}
      y1={184.614}
      x2={322.888}
      y2={184.614}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_86_)' d='M322.89 182.6l-13.95 8.06-6.99-4.04 13.95-8.05h.01z' />
    <linearGradient
      id='banner_svg__SVGID_87_'
      gradientUnits='userSpaceOnUse'
      x1={312.437}
      y1={190.733}
      x2={333.351}
      y2={190.733}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_87_)' d='M333.35 188.63l-13.67 8.22-7.24-4.18 13.95-8.06z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_14_'
        filterUnits='userSpaceOnUse'
        x={40.62}
        y={164.35}
        width={11.28}
        height={32.59}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={40.62} y={164.35} width={11.28} height={32.59} id='banner_svg__SVGID_88_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_14_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_15_'
            filterUnits='userSpaceOnUse'
            x={40.62}
            y={164.35}
            width={11.28}
            height={32.59}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_89_'
          gradientUnits='userSpaceOnUse'
          x1={46.249}
          y1={177.697}
          x2={46.307}
          y2={190.446}
        >
          <stop offset={0} stopColor='#999' />
          <stop offset={0.202} stopColor='#b4b4b4' />
          <stop offset={0.554} stopColor='#dcdcdc' />
          <stop offset={0.832} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          mask='url(#banner_svg__SVGID_88_)'
          fill='url(#banner_svg__SVGID_89_)'
          d='M40.62 170.87l11.28-6.52v26.07l-11.28 6.52z'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_90_'
      gradientUnits='userSpaceOnUse'
      x1={46.249}
      y1={177.697}
      x2={46.307}
      y2={190.446}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_90_)' d='M40.62 170.87l11.28-6.52v26.07l-11.28 6.52z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_16_'
        filterUnits='userSpaceOnUse'
        x={57.55}
        y={141.54}
        width={11.28}
        height={45.63}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={57.55} y={141.54} width={11.28} height={45.63} id='banner_svg__SVGID_91_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_16_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_17_'
            filterUnits='userSpaceOnUse'
            x={57.55}
            y={141.54}
            width={11.28}
            height={45.63}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_92_'
          gradientUnits='userSpaceOnUse'
          x1={63.175}
          y1={163.145}
          x2={63.46}
          y2={179.115}
        >
          <stop offset={0} stopColor='#999' />
          <stop offset={0.202} stopColor='#b4b4b4' />
          <stop offset={0.554} stopColor='#dcdcdc' />
          <stop offset={0.832} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          mask='url(#banner_svg__SVGID_91_)'
          fill='url(#banner_svg__SVGID_92_)'
          d='M57.55 148.06l11.29-6.52v39.11l-11.29 6.52z'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_93_'
      gradientUnits='userSpaceOnUse'
      x1={63.175}
      y1={163.145}
      x2={63.46}
      y2={179.115}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_93_)' d='M57.55 148.06l11.29-6.52v39.11l-11.29 6.52z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_18_'
        filterUnits='userSpaceOnUse'
        x={74.48}
        y={118.72}
        width={11.29}
        height={58.67}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={74.48} y={118.72} width={11.29} height={58.67} id='banner_svg__SVGID_94_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_18_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_19_'
            filterUnits='userSpaceOnUse'
            x={74.48}
            y={118.72}
            width={11.29}
            height={58.67}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_95_'
          gradientUnits='userSpaceOnUse'
          x1={80.011}
          y1={145.85}
          x2={81.447}
          y2={172.102}
        >
          <stop offset={0} stopColor='#999' />
          <stop offset={0.202} stopColor='#b4b4b4' />
          <stop offset={0.554} stopColor='#dcdcdc' />
          <stop offset={0.832} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          mask='url(#banner_svg__SVGID_94_)'
          fill='url(#banner_svg__SVGID_95_)'
          d='M74.48 125.24l11.3-6.52-.01 52.15-11.28 6.52z'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_96_'
      gradientUnits='userSpaceOnUse'
      x1={80.011}
      y1={145.85}
      x2={81.447}
      y2={172.102}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_96_)' d='M74.48 125.24l11.3-6.52-.01 52.15-11.28 6.52z' />
    <path
      className='banner_svg__st17'
      d='M461.35 203.35c-.25.85-.57 1.66-.97 2.41-.03.06-.06.13-.1.19-.64 1.14-1.43 2.07-2.33 2.78l-.03.02-.66.47c-2.9 1.85-6.77 1.69-10.62-.39l-.84-.49-32.75-18.96-4.45-2.58h-.01l-36.68-21.24-2.06-1.19-19.19-11.11c-.03-.01-.06-.03-.08-.04-1.79-.99-3.58-2.41-5.27-4.24a27.066 27.066 0 01-3.16-4.17c-1.97-3.17-3.29-6.66-3.85-10.04-.72-4.14-.33-8.12 1.35-11.11.81-1.45 1.86-2.54 3.07-3.3l.01-.01a8.277 8.277 0 013.36-1.17c1.54-.19 3.2.01 4.9.6.24.08.48.17.72.27 1.87.76 3.76 1.98 5.58 3.65 1.6-2.2 3.68-3.63 6.04-4.31 5.43-1.56 12.34.86 18.36 7.08.56-2.89 1.44-5.61 2.66-8.09.22-.44.45-.88.69-1.31 2.02-3.61 4.65-6.32 7.69-8.14l.01-.01c.7-.42 1.43-.79 2.17-1.12a19.83 19.83 0 015.46-1.51c9.46-1.27 20.84 3.59 30.73 14.3 14.5 15.71 20.99 38.75 16.32 54.91 2.42.94 4.91 2.66 7.2 5.14.41.44.8.89 1.17 1.36 5.16 6.4 7.37 15.12 5.56 21.35z'
    />
    <linearGradient
      id='banner_svg__SVGID_97_'
      gradientUnits='userSpaceOnUse'
      x1={363.313}
      y1={120.339}
      x2={384.332}
      y2={120.339}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M384.33 118.36c-1.22 2.47-2.09 5.19-2.66 8.09-6.02-6.22-12.94-8.64-18.36-7.08l.51-.36c4.51-6.2 12.81-6.3 20.51-.65z'
      fill='url(#banner_svg__SVGID_97_)'
    />
    <linearGradient
      id='banner_svg__SVGID_98_'
      gradientUnits='userSpaceOnUse'
      x1={342.72}
      y1={119.048}
      x2={363.825}
      y2={119.048}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M363.82 119l-.51.36c-2.36.68-4.44 2.11-6.04 4.31-1.81-1.67-3.71-2.89-5.58-3.65-.24-.1-.48-.19-.72-.27-1.7-.58-3.36-.79-4.9-.6-1.21.15-2.34.54-3.36 1.17l5.75-4.12.7-.5c2.6-1.66 5.97-1.72 9.41-.22 1.77.78 3.55 1.95 5.25 3.52z'
      fill='url(#banner_svg__SVGID_98_)'
    />
    <linearGradient
      id='banner_svg__SVGID_99_'
      gradientUnits='userSpaceOnUse'
      x1={392.722}
      y1={138.44}
      x2={455.451}
      y2={138.44}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M453.95 170.81l-6.55 4.68c4.67-16.17-1.82-39.21-16.32-54.91-9.89-10.72-21.28-15.57-30.73-14.3-1.91.25-3.74.76-5.46 1.51-.75.33-1.47.7-2.17 1.12l4.81-3.5.59-.43c10.38-7.26 26.28-3.41 39.51 10.92 14.51 15.7 21 38.74 16.32 54.91z'
      fill='url(#banner_svg__SVGID_99_)'
    />
    <linearGradient
      id='banner_svg__SVGID_100_'
      gradientUnits='userSpaceOnUse'
      x1={447.409}
      y1={189.766}
      x2={468.522}
      y2={189.766}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M466.82 201.27c-.76 1.36-1.73 2.41-2.84 3.16l-6.03 4.3c.9-.71 1.69-1.64 2.33-2.78.04-.06.07-.13.1-.19.41-.75.73-1.55.97-2.41 1.81-6.23-.4-14.95-5.58-21.37-.37-.47-.76-.92-1.17-1.36-2.29-2.48-4.77-4.2-7.2-5.14l6.55-4.68c2.42.94 4.91 2.66 7.2 5.14.41.44.8.89 1.17 1.36 5.95 7.38 7.98 17.78 4.5 23.97z'
      fill='url(#banner_svg__SVGID_100_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_20_'
        filterUnits='userSpaceOnUse'
        x={399.16}
        y={123.51}
        width={25.22}
        height={43.66}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={399.16} y={123.51} width={25.22} height={43.66} id='banner_svg__SVGID_101_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_20_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_21_'
            filterUnits='userSpaceOnUse'
            x={399.16}
            y={123.51}
            width={25.22}
            height={43.66}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_102_'
          gradientUnits='userSpaceOnUse'
          x1={399.157}
          y1={145.344}
          x2={424.375}
          y2={145.344}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          mask='url(#banner_svg__SVGID_101_)'
          fill='url(#banner_svg__SVGID_102_)'
          d='M399.16 123.51v29.11l25.21 14.56v-29.11z'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_103_'
      gradientUnits='userSpaceOnUse'
      x1={399.157}
      y1={145.344}
      x2={424.375}
      y2={145.344}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_103_)' d='M399.16 123.51v29.11l25.21 14.56v-29.11z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_22_'
        filterUnits='userSpaceOnUse'
        x={356.44}
        y={128.87}
        width={16.07}
        height={27.83}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={356.44} y={128.87} width={16.07} height={27.83} id='banner_svg__SVGID_104_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_22_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_23_'
            filterUnits='userSpaceOnUse'
            x={356.44}
            y={128.87}
            width={16.07}
            height={27.83}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_105_'
          gradientUnits='userSpaceOnUse'
          x1={356.441}
          y1={142.784}
          x2={372.51}
          y2={142.784}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          mask='url(#banner_svg__SVGID_104_)'
          fill='url(#banner_svg__SVGID_105_)'
          d='M356.44 128.87v18.55l16.07 9.28v-18.55z'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_106_'
      gradientUnits='userSpaceOnUse'
      x1={356.441}
      y1={142.784}
      x2={372.51}
      y2={142.784}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_106_)' d='M356.44 128.87v18.55l16.07 9.28v-18.55z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_24_'
        filterUnits='userSpaceOnUse'
        x={431.7}
        y={177.18}
        width={13.68}
        height={23.69}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={431.7} y={177.18} width={13.68} height={23.69} id='banner_svg__SVGID_107_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_24_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_25_'
            filterUnits='userSpaceOnUse'
            x={431.7}
            y={177.18}
            width={13.68}
            height={23.69}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_108_'
          gradientUnits='userSpaceOnUse'
          x1={431.705}
          y1={189.021}
          x2={445.386}
          y2={189.021}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          mask='url(#banner_svg__SVGID_107_)'
          fill='url(#banner_svg__SVGID_108_)'
          d='M431.7 177.18l.01 15.79 13.67 7.9.01-15.8z'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_109_'
      gradientUnits='userSpaceOnUse'
      x1={431.705}
      y1={189.021}
      x2={445.386}
      y2={189.021}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_109_)' d='M431.7 177.18l.01 15.79 13.67 7.9.01-15.8z' />
    <linearGradient
      id='banner_svg__SVGID_110_'
      gradientUnits='userSpaceOnUse'
      x1={390.755}
      y1={170.645}
      x2={420.168}
      y2={170.645}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_110_)' d='M390.75 157.3v9.7l29.42 16.99v-9.71z' />
    <linearGradient
      id='banner_svg__SVGID_111_'
      gradientUnits='userSpaceOnUse'
      x1={402}
      y1={136.185}
      x2={408.922}
      y2={136.185}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_111_)' d='M402 130.19v7.99l6.92 4v-7.99z' />
    <linearGradient
      id='banner_svg__SVGID_112_'
      gradientUnits='userSpaceOnUse'
      x1={401.861}
      y1={145.922}
      x2={412.432}
      y2={145.922}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M412.06 149.13c-.06 0-.13-.02-.19-.05l-9.82-5.67a.375.375 0 01.37-.65l9.82 5.67a.375.375 0 01-.18.7z'
      fill='url(#banner_svg__SVGID_112_)'
    />
    <linearGradient
      id='banner_svg__SVGID_113_'
      gradientUnits='userSpaceOnUse'
      x1={401.861}
      y1={149.468}
      x2={414.885}
      y2={149.468}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M414.51 153.39c-.06 0-.13-.02-.19-.05l-12.28-7.09a.375.375 0 01.37-.65l12.28 7.09a.375.375 0 01-.18.7z'
      fill='url(#banner_svg__SVGID_113_)'
    />
    <linearGradient
      id='banner_svg__SVGID_114_'
      gradientUnits='userSpaceOnUse'
      x1={401.861}
      y1={153.009}
      x2={417.339}
      y2={153.009}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M416.96 157.64c-.06 0-.13-.02-.19-.05l-14.73-8.51a.375.375 0 01.37-.65l14.73 8.51c.18.1.24.33.14.51-.06.12-.19.19-.32.19z'
      fill='url(#banner_svg__SVGID_114_)'
    />
    <linearGradient
      id='banner_svg__SVGID_115_'
      gradientUnits='userSpaceOnUse'
      x1={358.929}
      y1={137.724}
      x2={362.811}
      y2={137.724}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_115_)' d='M358.93 134.36v4.48l3.88 2.24v-4.48z' />
    <linearGradient
      id='banner_svg__SVGID_116_'
      gradientUnits='userSpaceOnUse'
      x1={358.851}
      y1={143.184}
      x2={364.779}
      y2={143.184}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M364.57 144.98c-.04 0-.07-.01-.1-.03l-5.51-3.18a.223.223 0 01-.08-.29c.06-.1.19-.13.29-.08l5.51 3.18c.1.06.13.19.08.29-.05.08-.12.11-.19.11z'
      fill='url(#banner_svg__SVGID_116_)'
    />
    <linearGradient
      id='banner_svg__SVGID_117_'
      gradientUnits='userSpaceOnUse'
      x1={358.851}
      y1={145.172}
      x2={366.154}
      y2={145.172}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M365.94 147.37c-.04 0-.07-.01-.1-.03l-6.88-3.97a.223.223 0 01-.08-.29c.06-.1.19-.13.29-.08l6.88 3.97c.1.06.13.19.08.29-.04.07-.11.11-.19.11z'
      fill='url(#banner_svg__SVGID_117_)'
    />
    <linearGradient
      id='banner_svg__SVGID_118_'
      gradientUnits='userSpaceOnUse'
      x1={358.851}
      y1={147.158}
      x2={367.531}
      y2={147.158}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M367.32 149.75c-.04 0-.07-.01-.1-.03l-8.26-4.77a.223.223 0 01-.08-.29c.06-.1.19-.14.29-.08l8.26 4.77c.1.06.13.19.08.29-.05.08-.12.11-.19.11z'
      fill='url(#banner_svg__SVGID_118_)'
    />
    <linearGradient
      id='banner_svg__SVGID_119_'
      gradientUnits='userSpaceOnUse'
      x1={433.437}
      y1={184.251}
      x2={436.666}
      y2={184.251}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_119_)' d='M433.44 181.46v3.72l3.23 1.87v-3.73z' />
    <linearGradient
      id='banner_svg__SVGID_120_'
      gradientUnits='userSpaceOnUse'
      x1={433.372}
      y1={188.793}
      x2={438.303}
      y2={188.793}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M438.13 190.29c-.03 0-.06-.01-.09-.02l-4.58-2.65a.176.176 0 01-.06-.24c.05-.08.16-.11.24-.06l4.58 2.65c.08.05.11.15.06.24-.03.05-.09.08-.15.08z'
      fill='url(#banner_svg__SVGID_120_)'
    />
    <linearGradient
      id='banner_svg__SVGID_121_'
      gradientUnits='userSpaceOnUse'
      x1={433.372}
      y1={190.448}
      x2={439.447}
      y2={190.448}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M439.27 192.28c-.03 0-.06-.01-.09-.02l-5.73-3.31a.176.176 0 01-.06-.24c.05-.08.16-.11.24-.06l5.73 3.31c.08.05.11.15.06.24-.03.04-.09.08-.15.08z'
      fill='url(#banner_svg__SVGID_121_)'
    />
    <linearGradient
      id='banner_svg__SVGID_122_'
      gradientUnits='userSpaceOnUse'
      x1={433.372}
      y1={192.1}
      x2={440.592}
      y2={192.1}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M440.42 194.26c-.03 0-.06-.01-.09-.02l-6.87-3.97a.176.176 0 01-.06-.24c.05-.08.16-.11.24-.06l6.87 3.97c.08.05.11.15.06.24-.03.05-.09.08-.15.08z'
      fill='url(#banner_svg__SVGID_122_)'
    />
    <linearGradient
      id='banner_svg__SVGID_123_'
      gradientUnits='userSpaceOnUse'
      x1={399.794}
      y1={125.289}
      x2={428.006}
      y2={125.289}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_123_)'
      d='M427.68 135.64l-10.54-6.09-3.48-6.03-13.87-8.01.33-.57 14.02 8.1 3.49 6.03 10.38 6z'
    />
    <linearGradient
      id='banner_svg__SVGID_124_'
      gradientUnits='userSpaceOnUse'
      x1={411.05}
      y1={119.109}
      x2={426.946}
      y2={119.109}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_124_)' d='M426.62 125.58l-12.46-7.17-3.11-5.44.57-.33 3.03 5.29 12.3 7.07z' />
    <linearGradient
      id='banner_svg__SVGID_125_'
      gradientUnits='userSpaceOnUse'
      x1={433.073}
      y1={162.32}
      x2={441.965}
      y2={162.32}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_125_)'
      d='M441.4 177.35l-4.75-7.64v-14.58l-3.58-7.56.6-.28 3.64 7.69v14.54l4.65 7.48z'
    />
    <linearGradient
      id='banner_svg__SVGID_126_'
      gradientUnits='userSpaceOnUse'
      x1={428.41}
      y1={157.474}
      x2={437.147}
      y2={157.474}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_126_)' d='M436.82 160.19l-8.41-4.86.33-.57 8.41 4.85z' />
    <linearGradient
      id='banner_svg__SVGID_127_'
      gradientUnits='userSpaceOnUse'
      x1={432.521}
      y1={136.741}
      x2={435.174}
      y2={136.741}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_127_)' d='M435.17 143.29h-.66v-9.38l-1.99-3.39.57-.33 2.08 3.54z' />
    <linearGradient
      id='banner_svg__SVGID_128_'
      gradientUnits='userSpaceOnUse'
      x1={386.277}
      y1={136.579}
      x2={391.329}
      y2={136.579}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_128_)' d='M386.83 149.65l-.55-.36 4.39-6.6-.25-19.17.66-.01.25 19.37z' />
    <linearGradient
      id='banner_svg__SVGID_129_'
      gradientUnits='userSpaceOnUse'
      x1={377.815}
      y1={148.381}
      x2={378.48}
      y2={148.381}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_129_)' d='M377.82 141.94h.66v12.89h-.66z' />
    <linearGradient
      id='banner_svg__SVGID_130_'
      gradientUnits='userSpaceOnUse'
      x1={344.245}
      y1={137.75}
      x2={353.224}
      y2={137.75}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_130_)'
      d='M352.66 150.37l-4.26-7.1v-9.97l-4.15-7.86.58-.31 4.23 8v9.96l4.16 6.94z'
    />
    <linearGradient
      id='banner_svg__SVGID_131_'
      gradientUnits='userSpaceOnUse'
      x1={344.318}
      y1={136.218}
      x2={348.95}
      y2={136.218}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_131_)' d='M348.51 138.32l-4.19-3.71.44-.49 4.19 3.71z' />
    <linearGradient
      id='banner_svg__SVGID_132_'
      gradientUnits='userSpaceOnUse'
      x1={449.555}
      y1={197.345}
      x2={454.079}
      y2={197.345}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_132_)' d='M450.22 205.83h-.66v-9.79l3.94-7.18.58.32-3.86 7.03z' />
    <linearGradient
      id='banner_svg__SVGID_133_'
      gradientUnits='userSpaceOnUse'
      x1={343.128}
      y1={125.206}
      x2={345.434}
      y2={125.206}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M343.3 124.16c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.54-.59-1.25-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_133_)'
    />
    <linearGradient
      id='banner_svg__SVGID_134_'
      gradientUnits='userSpaceOnUse'
      x1={343.161}
      y1={133.741}
      x2={345.467}
      y2={133.741}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M343.33 132.69c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.53-.58-1.24-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_134_)'
    />
    <linearGradient
      id='banner_svg__SVGID_135_'
      gradientUnits='userSpaceOnUse'
      x1={351.705}
      y1={149.949}
      x2={354.012}
      y2={149.949}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M351.88 148.9c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.54-.58-1.25-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_135_)'
    />
    <linearGradient
      id='banner_svg__SVGID_136_'
      gradientUnits='userSpaceOnUse'
      x1={376.662}
      y1={141.938}
      x2={378.968}
      y2={141.938}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M376.83 140.89c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.53-.58-1.24-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_136_)'
    />
    <linearGradient
      id='banner_svg__SVGID_137_'
      gradientUnits='userSpaceOnUse'
      x1={376.662}
      y1={154.825}
      x2={378.968}
      y2={154.825}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M376.83 153.78c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.53-.59-1.24-.67-1.58-.18z'
      fill='url(#banner_svg__SVGID_137_)'
    />
    <linearGradient
      id='banner_svg__SVGID_138_'
      gradientUnits='userSpaceOnUse'
      x1={389.373}
      y1={122.611}
      x2={391.679}
      y2={122.611}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M389.54 121.56c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.53-.58-1.24-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_138_)'
    />
    <linearGradient
      id='banner_svg__SVGID_139_'
      gradientUnits='userSpaceOnUse'
      x1={381.091}
      y1={167.085}
      x2={383.397}
      y2={167.085}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M381.26 166.04c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.53-.59-1.24-.67-1.58-.18z'
      fill='url(#banner_svg__SVGID_139_)'
    />
    <linearGradient
      id='banner_svg__SVGID_140_'
      gradientUnits='userSpaceOnUse'
      x1={382.02}
      y1={150.117}
      x2={386.887}
      y2={150.117}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_140_)'
      d='M382.68 167.19h-.66v-9.91l4.2-2.42.01-14.27-4.17-7.21.58-.33 4.25 7.36-.01 14.83-4.2 2.42z'
    />
    <linearGradient
      id='banner_svg__SVGID_141_'
      gradientUnits='userSpaceOnUse'
      x1={381.098}
      y1={133.214}
      x2={383.404}
      y2={133.214}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M381.27 132.17c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.54-.59-1.25-.67-1.58-.18z'
      fill='url(#banner_svg__SVGID_141_)'
    />
    <linearGradient
      id='banner_svg__SVGID_142_'
      gradientUnits='userSpaceOnUse'
      x1={398.447}
      y1={114.941}
      x2={400.753}
      y2={114.941}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M398.62 113.89c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.54-.58-1.25-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_142_)'
    />
    <linearGradient
      id='banner_svg__SVGID_143_'
      gradientUnits='userSpaceOnUse'
      x1={427.173}
      y1={135.638}
      x2={429.479}
      y2={135.638}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M427.35 134.59c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.54-.58-1.25-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_143_)'
    />
    <linearGradient
      id='banner_svg__SVGID_144_'
      gradientUnits='userSpaceOnUse'
      x1={425.793}
      y1={125.289}
      x2={428.099}
      y2={125.289}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M425.96 124.24c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.53-.58-1.24-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_144_)'
    />
    <linearGradient
      id='banner_svg__SVGID_145_'
      gradientUnits='userSpaceOnUse'
      x1={409.897}
      y1={112.643}
      x2={412.204}
      y2={112.643}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M410.07 111.59c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.54-.58-1.25-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_145_)'
    />
    <linearGradient
      id='banner_svg__SVGID_146_'
      gradientUnits='userSpaceOnUse'
      x1={431.561}
      y1={129.865}
      x2={433.867}
      y2={129.865}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M431.73 128.82c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.53-.59-1.24-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_146_)'
    />
    <linearGradient
      id='banner_svg__SVGID_147_'
      gradientUnits='userSpaceOnUse'
      x1={433.67}
      y1={143.293}
      x2={435.976}
      y2={143.293}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M433.84 142.24c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.53-.58-1.24-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_147_)'
    />
    <linearGradient
      id='banner_svg__SVGID_148_'
      gradientUnits='userSpaceOnUse'
      x1={432.517}
      y1={147.611}
      x2={434.823}
      y2={147.611}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M432.69 146.56c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.54-.58-1.25-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_148_)'
    />
    <linearGradient
      id='banner_svg__SVGID_149_'
      gradientUnits='userSpaceOnUse'
      x1={427.465}
      y1={155.04}
      x2={429.771}
      y2={155.04}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M427.64 153.99c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.54-.58-1.25-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_149_)'
    />
    <linearGradient
      id='banner_svg__SVGID_150_'
      gradientUnits='userSpaceOnUse'
      x1={440.553}
      y1={177.176}
      x2={442.859}
      y2={177.176}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M440.72 176.13c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.53-.59-1.24-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_150_)'
    />
    <linearGradient
      id='banner_svg__SVGID_151_'
      gradientUnits='userSpaceOnUse'
      x1={452.556}
      y1={188.953}
      x2={454.862}
      y2={188.953}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M452.73 187.9c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.54-.58-1.25-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_151_)'
    />
    <linearGradient
      id='banner_svg__SVGID_152_'
      gradientUnits='userSpaceOnUse'
      x1={448.501}
      y1={205.74}
      x2={450.807}
      y2={205.74}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M448.67 204.69c-.15.22-.2.52-.16.83a1.946 1.946 0 00.53 1.09c.54.58 1.25.66 1.59.18.33-.48.17-1.34-.38-1.92-.53-.58-1.24-.66-1.58-.18z'
      fill='url(#banner_svg__SVGID_152_)'
    />
    <linearGradient
      id='banner_svg__SVGID_153_'
      gradientUnits='userSpaceOnUse'
      x1={351.085}
      y1={225.748}
      x2={432.148}
      y2={225.748}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_153_)' d='M351.08 222.15l49.89 28.8 31.18-18-31.18-18v-7.2l-12.47-7.2z' />
    <linearGradient
      id='banner_svg__SVGID_154_'
      gradientUnits='userSpaceOnUse'
      x1={400.968}
      y1={222.156}
      x2={435.266}
      y2={222.156}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_154_)' d='M400.97 211.36l34.3 19.79-3.12 1.8-31.18-18z' />
    <linearGradient
      id='banner_svg__SVGID_155_'
      gradientUnits='userSpaceOnUse'
      x1={351.085}
      y1={238.245}
      x2={400.968}
      y2={238.245}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_155_)' d='M351.08 222.15v3.39l49.89 28.8v-3.39z' />
    <linearGradient
      id='banner_svg__SVGID_156_'
      gradientUnits='userSpaceOnUse'
      x1={400.968}
      y1={243.645}
      x2={432.148}
      y2={243.645}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_156_)' d='M400.97 250.95v3.39l31.18-18v-3.39z' />
    <linearGradient
      id='banner_svg__SVGID_157_'
      gradientUnits='userSpaceOnUse'
      x1={432.148}
      y1={233.744}
      x2={435.266}
      y2={233.744}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_157_)' d='M435.27 234.54v-3.39l-3.12 1.8v3.39z' />
    <linearGradient
      id='banner_svg__SVGID_158_'
      gradientUnits='userSpaceOnUse'
      x1={344.967}
      y1={268.358}
      x2={393.252}
      y2={268.358}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_158_)'
      d='M344.97 266.21l29.71 17.16 18.57-10.72-18.57-10.73v-4.29l-7.43-4.28z'
    />
    <linearGradient
      id='banner_svg__SVGID_159_'
      gradientUnits='userSpaceOnUse'
      x1={374.68}
      y1={266.218}
      x2={395.109}
      y2={266.218}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_159_)' d='M374.68 259.79l20.43 11.78-1.86 1.08-18.57-10.73z' />
    <linearGradient
      id='banner_svg__SVGID_160_'
      gradientUnits='userSpaceOnUse'
      x1={344.967}
      y1={275.801}
      x2={374.68}
      y2={275.801}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_160_)' d='M344.97 266.21v2.02l29.71 17.16v-2.02z' />
    <linearGradient
      id='banner_svg__SVGID_161_'
      gradientUnits='userSpaceOnUse'
      x1={374.68}
      y1={279.018}
      x2={393.252}
      y2={279.018}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_161_)' d='M374.68 283.37v2.02l18.57-10.72v-2.02z' />
    <linearGradient
      id='banner_svg__SVGID_162_'
      gradientUnits='userSpaceOnUse'
      x1={393.252}
      y1={273.121}
      x2={395.109}
      y2={273.121}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_162_)' d='M395.11 273.59v-2.02l-1.86 1.08v2.02z' />
    <linearGradient
      id='banner_svg__SVGID_163_'
      gradientUnits='userSpaceOnUse'
      x1={131.105}
      y1={279.911}
      x2={173.015}
      y2={279.911}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_163_)' d='M131.11 279.91l20.95-12.1 20.95 12.09-20.95 12.11z' />
    <linearGradient
      id='banner_svg__SVGID_164_'
      gradientUnits='userSpaceOnUse'
      x1={131.105}
      y1={288.978}
      x2={152.06}
      y2={288.978}
    >
      <stop offset={0} stopColor='#e9f1ff' />
      <stop offset={1} stopColor='#d4e0ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_164_)' d='M131.11 279.91v6.04l20.95 12.1v-6.04z' />
    <linearGradient
      id='banner_svg__SVGID_165_'
      gradientUnits='userSpaceOnUse'
      x1={152.06}
      y1={288.972}
      x2={173.015}
      y2={288.972}
    >
      <stop offset={0} stopColor='#e9f1ff' />
      <stop offset={1} stopColor='#d4e0ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_165_)' d='M152.06 292.01v6.04l20.95-12.12v-6.03z' />
    <linearGradient
      id='banner_svg__SVGID_166_'
      gradientUnits='userSpaceOnUse'
      x1={131.083}
      y1={270.81}
      x2={172.993}
      y2={270.81}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_166_)' d='M131.08 270.81l20.96-12.1 20.95 12.09-20.95 12.11z' />
    <linearGradient
      id='banner_svg__SVGID_167_'
      gradientUnits='userSpaceOnUse'
      x1={131.083}
      y1={279.877}
      x2={152.038}
      y2={279.877}
    >
      <stop offset={0} stopColor='#e9f1ff' />
      <stop offset={1} stopColor='#d4e0ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_167_)' d='M131.08 270.81v6.04l20.96 12.09v-6.03z' />
    <linearGradient
      id='banner_svg__SVGID_168_'
      gradientUnits='userSpaceOnUse'
      x1={152.038}
      y1={279.871}
      x2={172.993}
      y2={279.871}
    >
      <stop offset={0} stopColor='#e9f1ff' />
      <stop offset={1} stopColor='#d4e0ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_168_)' d='M152.04 282.91v6.03l20.95-12.11v-6.03z' />
    <linearGradient
      id='banner_svg__SVGID_169_'
      gradientUnits='userSpaceOnUse'
      x1={131.083}
      y1={261.743}
      x2={172.992}
      y2={261.743}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_169_)' d='M131.08 261.74l20.96-12.1 20.95 12.09-20.95 12.11z' />
    <linearGradient
      id='banner_svg__SVGID_170_'
      gradientUnits='userSpaceOnUse'
      x1={131.083}
      y1={270.811}
      x2={152.038}
      y2={270.811}
    >
      <stop offset={0} stopColor='#e9f1ff' />
      <stop offset={1} stopColor='#d4e0ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_170_)' d='M131.08 261.74v6.04l20.96 12.1v-6.04z' />
    <linearGradient
      id='banner_svg__SVGID_171_'
      gradientUnits='userSpaceOnUse'
      x1={152.038}
      y1={270.804}
      x2={172.992}
      y2={270.804}
    >
      <stop offset={0} stopColor='#e9f1ff' />
      <stop offset={1} stopColor='#d4e0ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_171_)' d='M152.04 273.84v6.04l20.95-12.11v-6.04z' />
    <linearGradient
      id='banner_svg__SVGID_172_'
      gradientUnits='userSpaceOnUse'
      x1={260.658}
      y1={201.806}
      x2={262.155}
      y2={201.806}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M262.15 207.26l-.48.5-1.01-1.65s.11-1.51.41-3.89c.31-2.38.15-5 .15-5l.81-1.37c.08.86.13 1.79.07 2.97-.14 2.89-.31 4.15-.17 6.46.03.69.12 1.37.22 1.98z'
      fill='url(#banner_svg__SVGID_172_)'
    />
    <path
      className='banner_svg__st187'
      d='M271.84 238.2s.5 1.22.39 2.6c-.11 1.39-1.21.67-4.17.02-1.94-.43-3.53-.89-3.29-1.51.24-.63 4.39-1.23 4.39-1.23l1.32-.14 1.36.26zM268.14 241.86s.51 1.24.39 2.64c-.11 1.41-1.23.68-4.24.02-1.97-.43-3.58-.9-3.34-1.54.24-.64 4.45-1.25 4.45-1.25l1.34-.14 1.4.27z'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_26_'
        filterUnits='userSpaceOnUse'
        x={266.85}
        y={203.57}
        width={8.14}
        height={34.96}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={266.85} y={203.57} width={8.14} height={34.96} id='banner_svg__SVGID_173_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_26_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_27_'
            filterUnits='userSpaceOnUse'
            x={266.85}
            y={203.57}
            width={8.14}
            height={34.96}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_174_'
          gradientUnits='userSpaceOnUse'
          x1={266.854}
          y1={221.054}
          x2={274.998}
          y2={221.054}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M274.99 204.85s.15 3.15-.89 4.68c-1.03 1.53-2.16 7.42-2.27 13.25-.11 5.83 0 15.42 0 15.42s-.69.42-1.5.32c-.81-.1-1.18-.44-1.18-.44l-2.3-31.8.94-2.71 7.2 1.28z'
          mask='url(#banner_svg__SVGID_173_)'
          fill='url(#banner_svg__SVGID_174_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_175_'
      gradientUnits='userSpaceOnUse'
      x1={266.854}
      y1={221.054}
      x2={274.998}
      y2={221.054}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.547} stopColor='#daeaff' />
      <stop offset={0.999} stopColor='#d4e7ff' />
    </linearGradient>
    <path
      d='M274.99 204.85s.15 3.15-.89 4.68c-1.03 1.53-2.16 7.42-2.27 13.25-.11 5.83 0 15.42 0 15.42s-.69.42-1.5.32c-.81-.1-1.18-.44-1.18-.44l-2.3-31.8.94-2.71 7.2 1.28z'
      fill='url(#banner_svg__SVGID_175_)'
    />
    <linearGradient
      id='banner_svg__SVGID_176_'
      gradientUnits='userSpaceOnUse'
      x1={263.079}
      y1={224.456}
      x2={271.343}
      y2={224.456}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M271.34 208.02s.15 3.2-.9 4.75c-1.05 1.55-2.19 7.53-2.3 13.45-.11 5.91 0 15.65 0 15.65s-.7.42-1.52.32c-.82-.1-1.2-.45-1.2-.45l-2.33-32.27.96-2.75 7.29 1.3z'
      fill='url(#banner_svg__SVGID_176_)'
    />
    <linearGradient
      id='banner_svg__SVGID_177_'
      gradientUnits='userSpaceOnUse'
      x1={260.39}
      y1={182.483}
      x2={264.331}
      y2={182.483}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M263.78 182.65c-.69.65-.79 1.59-.79 1.59s-.41.58-.64.54c-.23-.03-1.82-.74-1.82-.74s.47-.78.09-1.25c-.39-.47-.21-.89-.07-1.18.08-.16.41-.51.78-.83.47-.4 1-.74 1.1-.54.18.37-.69 1.35-.49 1.36h.01c.54 0 1.45-.03 2.37-.27 0 .01.15.67-.54 1.32z'
      fill='url(#banner_svg__SVGID_177_)'
    />
    <linearGradient
      id='banner_svg__SVGID_178_'
      gradientUnits='userSpaceOnUse'
      x1={259.766}
      y1={190.347}
      x2={263.26}
      y2={190.347}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M260.76 196.74s-.94-1.84-.98-6.92c-.04-5.08 0-5.57 0-5.57s.83-.32 1.72-.28 1.76.37 1.76.37l-.19 3.54-2.31 8.86z'
      fill='url(#banner_svg__SVGID_178_)'
    />
    <linearGradient
      id='banner_svg__SVGID_179_'
      gradientUnits='userSpaceOnUse'
      x1={264.603}
      y1={182.506}
      x2={267.965}
      y2={182.506}
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M264.61 181.26s.13 1.5 0 2.42c-.13.93 1.39 1.68 1.44 1.65.05-.04 1.91-1.95 1.91-1.95l-.45-2.81s-.05-.47-.19-.52c-.14-.05-2.02-.37-2.02-.37l-.69 1.58z'
      fill='url(#banner_svg__SVGID_179_)'
    />
    <linearGradient
      id='banner_svg__SVGID_180_'
      gradientUnits='userSpaceOnUse'
      x1={261.304}
      y1={178.291}
      x2={267.329}
      y2={178.291}
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M267.04 179.56c-.59 1-1.67 1.52-2.72 1.78-.93.24-1.83.27-2.37.27h-.01c-.2-.01.67-1 .49-1.36-.1-.21-.63.13-1.1.54-.04-.55-.05-1.32.1-2.1.28-1.49.58-2.25.58-2.25l2.34-1.45c0-.01 4.09 2.18 2.69 4.57z'
      fill='url(#banner_svg__SVGID_180_)'
    />
    <linearGradient
      id='banner_svg__SVGID_181_'
      gradientUnits='userSpaceOnUse'
      x1={261.503}
      y1={196.078}
      x2={275.008}
      y2={196.078}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M275.01 205.07s-.51 1.87-3.5 3.36c-2.99 1.49-8.76 1.35-8.76 1.35s-.34-1.09-.58-2.53c-.1-.61-.19-1.29-.24-1.97-.14-2.31.03-3.57.17-6.46.06-1.17.02-2.11-.07-2.97-.13-1.26-.35-2.36-.49-3.83-.24-2.49 1.09-6.38 1.93-7.39.84-1.02 3.38-1.95 3.38-1.95s1.81-.41 3.26-.3c.59.05 1.07.42 1.43 1.07 0 0 .01.01.02.03.05.09.09.18.13.27 0 .01.01.03.02.04.37.85.57 2.07.56 3.59 0 .22 0 .44-.01.67-.1 2.34.43 4.58 1.06 6.77.3 1.04.61 2.06.9 3.09.88 3.19.79 7.16.79 7.16z'
      fill='url(#banner_svg__SVGID_181_)'
    />
    <linearGradient
      id='banner_svg__SVGID_182_'
      gradientUnits='userSpaceOnUse'
      x1={264.855}
      y1={187.14}
      x2={261.032}
      y2={196.621}
    >
      <stop offset={0.001} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M265.83 186.39c-.43-1.05-1.75-1.41-2.62-.69-.52.43-1.07 1.18-1.53 2.47-1.3 3.65-1.88 8.3-.46 9.04 2.94 1.54 4.14-4.94 4.77-7.89.27-1.3.11-2.26-.16-2.93z'
      fill='url(#banner_svg__SVGID_182_)'
    />
    <linearGradient
      id='banner_svg__SVGID_183_'
      gradientUnits='userSpaceOnUse'
      x1={271.699}
      y1={189.319}
      x2={274.531}
      y2={189.319}
    >
      <stop offset={0} stopColor='#005eff' />
      <stop offset={1} stopColor='#6aaeff' />
    </linearGradient>
    <path
      d='M273.31 194.84c-.63-2.19-1.16-4.43-1.06-6.77.07-1.83-.13-3.28-.55-4.26.57 1.2 2.42 5.21 2.79 7.59.21 1.33-.44 2.53-1.18 3.44z'
      fill='url(#banner_svg__SVGID_183_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_28_'
        filterUnits='userSpaceOnUse'
        x={268.26}
        y={187.39}
        width={6.75}
        height={21.04}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={268.26} y={187.39} width={6.75} height={21.04} id='banner_svg__SVGID_184_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_28_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_29_'
            filterUnits='userSpaceOnUse'
            x={268.26}
            y={187.39}
            width={6.75}
            height={21.04}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_185_'
          gradientUnits='userSpaceOnUse'
          x1={268.261}
          y1={197.915}
          x2={275.008}
          y2={197.915}
        >
          <stop offset={0.003} />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M275.01 205.07s-.51 1.87-3.5 3.36c2.78-2.62 1.84-7.06-1.73-7.62-3.58-.56.17-3.72 1.47-4.74s-.07-3.53-2.03-3.6c-1.96-.07 3.05-5.08 3.05-5.08 0 .22 0 .44-.01.67-.1 2.34.43 4.58 1.06 6.77.3 1.04.61 2.06.9 3.09.88 3.18.79 7.15.79 7.15z'
          mask='url(#banner_svg__SVGID_184_)'
          fill='url(#banner_svg__SVGID_185_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_186_'
      gradientUnits='userSpaceOnUse'
      x1={268.261}
      y1={197.915}
      x2={275.008}
      y2={197.915}
    >
      <stop offset={0.003} stopColor='#6a8cff' />
      <stop offset={1} stopColor='#0011bf' />
    </linearGradient>
    <path
      d='M275.01 205.07s-.51 1.87-3.5 3.36c2.78-2.62 1.84-7.06-1.73-7.62-3.58-.56.17-3.72 1.47-4.74s-.07-3.53-2.03-3.6c-1.96-.07 3.05-5.08 3.05-5.08 0 .22 0 .44-.01.67-.1 2.34.43 4.58 1.06 6.77.3 1.04.61 2.06.9 3.09.88 3.18.79 7.15.79 7.15z'
      fill='url(#banner_svg__SVGID_186_)'
    />
    <path
      className='banner_svg__st187'
      d='M260.94 172.64s-.54 2.45 1.07 3.79c1.61 1.33 1.62 1.52 1.77 2.1.15.58-.16 1.17-.16 1.17s1.22-1.29 1.54-.86-.5 1.66.64 1.92c1.14.26 2.98-.45 3.56-1.71.58-1.26.36-2.45.36-2.45s.15-2.31-1.19-3.27c-.97-.7-1.18-.12-1.97-.56-.27-.15-.03-1.03-1.05-1.03-.77 0-1.1.89-1.99.47-.72-.34.24-1.32-.74-1.77-.97-.45-1.84 2.2-1.84 2.2z'
    />
    <linearGradient
      id='banner_svg__SVGID_187_'
      gradientUnits='userSpaceOnUse'
      x1={392.679}
      y1={233.591}
      x2={400.264}
      y2={233.591}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M393 232.16s-1.16 3.71.93 3.51c2.08-.19 6.52-2.59 6.33-3.51-.19-.93-4.92-.1-4.92-.1l-1.49-.55-.85.65z'
      fill='url(#banner_svg__SVGID_187_)'
    />
    <linearGradient
      id='banner_svg__SVGID_188_'
      gradientUnits='userSpaceOnUse'
      x1={387.332}
      y1={232.3}
      x2={394.844}
      y2={232.3}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M387.65 230.88s-1.15 3.67.92 3.48c2.06-.19 6.46-2.56 6.27-3.48-.19-.92-4.87-.1-4.87-.1l-1.48-.55-.84.65z'
      fill='url(#banner_svg__SVGID_188_)'
    />
    <linearGradient
      id='banner_svg__SVGID_189_'
      gradientUnits='userSpaceOnUse'
      x1={390.344}
      y1={219.79}
      x2={395.015}
      y2={219.79}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M390.34 209.28s1.16 3.99 1.03 6.36 1.59 17.03 1.59 17.03.2.33.91.29c.71-.04 1.1-.38 1.1-.38s.23-23.31-.35-24.91-4.27-.88-4.27-.88v2.49z'
      fill='url(#banner_svg__SVGID_189_)'
    />
    <linearGradient
      id='banner_svg__SVGID_190_'
      gradientUnits='userSpaceOnUse'
      x1={385.141}
      y1={218.819}
      x2={389.767}
      y2={218.819}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M385.14 208.41s1.15 3.95 1.02 6.3c-.13 2.35 1.58 16.86 1.58 16.86s.2.33.9.28c.7-.04 1.09-.37 1.09-.37s.22-23.09-.35-24.67c-.57-1.58-4.23-.87-4.23-.87v2.47z'
      fill='url(#banner_svg__SVGID_190_)'
    />
    <linearGradient
      id='banner_svg__SVGID_191_'
      gradientUnits='userSpaceOnUse'
      x1={383.184}
      y1={202.303}
      x2={397.636}
      y2={202.303}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M397.64 209.21s-1.66-.76-2.92-.72c-2.75.08-2.46.82-5.37.97-2.87.14-5.83-1.85-5.83-1.85.01-.38-.73-1.64-.03-4.13.65-2.31.36-3.16.07-4.31.52-.55 1.38-1.11 2.69-.83 2.35.5 6.54-.76 6.54-.76-1.45.29-3.17.39-4.98-.03-.55-.13-1.05-.26-1.5-.39-1.48-.43-2.4-.86-2.97-1.2l1.84-.15c1.37-.11 3.3-.26 5.1-.39 2.43-.18 4.62-.31 4.82-.24.46.16 1.26 1.21 1.26 1.21l1.28 12.82z'
      fill='url(#banner_svg__SVGID_191_)'
    />
    <linearGradient
      id='banner_svg__SVGID_192_'
      gradientUnits='userSpaceOnUse'
      x1={380.556}
      y1={180.394}
      x2={387.293}
      y2={180.394}
    >
      <stop offset={0} stopColor='#d4e3ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M383.73 174.17s-3.36 6.19-3.16 8.75 6.73 3.69 6.73 3.69l-.3-6.59-3.27-5.85z'
      fill='url(#banner_svg__SVGID_192_)'
    />
    <linearGradient
      id='banner_svg__SVGID_193_'
      gradientUnits='userSpaceOnUse'
      x1={388.37}
      y1={166.187}
      x2={394.626}
      y2={166.187}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M393.12 164.2s1.2 1.79.91 2.43c-.14.31 1.17 2.08.3 2.68-1.18.82-2.98.71-4.88-.82-1.9-1.52-.71-4.29-.71-4.29l1.97-1.64 2.41 1.64z'
      fill='url(#banner_svg__SVGID_193_)'
    />
    <linearGradient
      id='banner_svg__SVGID_194_'
      gradientUnits='userSpaceOnUse'
      x1={402.734}
      y1={169.809}
      x2={405.948}
      y2={169.809}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M402.73 171.25s1.83-4.99 2.15-4.67c.32.32-.39 1.91.18 2.57.57.66 1.32.96.58 1.88s-2.75 2.03-2.75 2.03l-.16-1.81z'
      fill='url(#banner_svg__SVGID_194_)'
    />
    <linearGradient
      id='banner_svg__SVGID_195_'
      gradientUnits='userSpaceOnUse'
      x1={382.421}
      y1={184.313}
      x2={403.973}
      y2={184.313}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M398.71 183.69c-2.59-1.32-3.57-3.09-3.69-3.32.08.18.58 1.42.31 2.03-.09.2-.22.38-.34.56 0 0-.01.02-.03.04-.01.02-.03.03-.04.05-.01.01-.02.03-.03.04-.26.38-.47.81-.27 1.49.19.65.56 1.31.93 2.43.25.75.51 1.72.72 3.03.53 3.26.59 6.04.59 6.04s-.19.11-.53.29c-.68.33-1.95.89-3.57 1.21-1.45.29-3.17.39-4.98-.03-.55-.13-1.05-.26-1.5-.39-1.48-.43-2.4-.86-2.97-1.2-.76-.46-.9-.78-.9-.78s.04-.39.11-1.03c.22-1.98.72-6.32 1.13-8.2.35-1.62-.05-3.73-.34-5.97-.15-1.18-.28-2.4-.24-3.59.14-4.45 5.5-4.62 5.5-4.62s3.86-.2 6.04 2.71c1.47 1.97 2.81 3.29 3.68 3.63.42.17.72.1.88-.23.1-.22.28-.71.52-1.33v-.01c.89-2.32 2.55-6.5 3.86-5.62 1.67 1.1-2.06 14.18-4.84 12.77z'
      fill='url(#banner_svg__SVGID_195_)'
    />
    <linearGradient
      id='banner_svg__SVGID_196_'
      gradientUnits='userSpaceOnUse'
      x1={391.701}
      y1={191.755}
      x2={395.082}
      y2={191.755}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M391.7 192.04s1.39-.22 2.06-.53c.66-.31 1.33-1.04 1.33-1.04s-.31 1.24-.88 1.87c-.56.63-.73.7-.73.7s.31-1.06-.28-.98c-.59.08-1.5-.02-1.5-.02z'
      fill='url(#banner_svg__SVGID_196_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_30_'
        filterUnits='userSpaceOnUse'
        x={392.64}
        y={183.1}
        width={2.92}
        height={3.92}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={392.64} y={183.1} width={2.92} height={3.92} id='banner_svg__SVGID_197_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_30_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_31_'
            filterUnits='userSpaceOnUse'
            x={392.64}
            y={183.1}
            width={2.92}
            height={3.92}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_198_'
          gradientUnits='userSpaceOnUse'
          x1={392.636}
          y1={185.055}
          x2={395.557}
          y2={185.055}
        >
          <stop offset={0.001} stopColor='#fff' />
          <stop offset={0.13} stopColor='#f9f9f9' />
          <stop offset={0.302} stopColor='#e8e8e8' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.716} stopColor='#a5a5a5' />
          <stop offset={0.944} stopColor='#737373' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M395.56 187.01c-.08-.08-1.41-1.56-2.28-1.8-.9-.25-.85-.32.11-.8.74-.37 1.29-1.04 1.51-1.32-.26.38-.47.81-.27 1.49.19.66.55 1.32.93 2.43z'
          mask='url(#banner_svg__SVGID_197_)'
          fill='url(#banner_svg__SVGID_198_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_199_'
      gradientUnits='userSpaceOnUse'
      x1={392.636}
      y1={185.055}
      x2={395.557}
      y2={185.055}
    >
      <stop offset={0.001} stopColor='#94b9ff' />
      <stop offset={0.188} stopColor='#9abdff' />
      <stop offset={0.438} stopColor='#abc8ff' />
      <stop offset={0.723} stopColor='#c7dbff' />
      <stop offset={1} stopColor='#e9f2ff' />
    </linearGradient>
    <path
      d='M395.56 187.01c-.08-.08-1.41-1.56-2.28-1.8-.9-.25-.85-.32.11-.8.74-.37 1.29-1.04 1.51-1.32-.26.38-.47.81-.27 1.49.19.66.55 1.32.93 2.43z'
      fill='url(#banner_svg__SVGID_199_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_32_'
        filterUnits='userSpaceOnUse'
        x={382.42}
        y={179.98}
        width={5.53}
        height={17.17}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={382.42} y={179.98} width={5.53} height={17.17} id='banner_svg__SVGID_200_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_32_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_33_'
            filterUnits='userSpaceOnUse'
            x={382.42}
            y={179.98}
            width={5.53}
            height={17.17}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_201_'
          gradientUnits='userSpaceOnUse'
          x1={382.421}
          y1={188.564}
          x2={387.95}
          y2={188.564}
        >
          <stop offset={0.001} stopColor='#fff' />
          <stop offset={0.13} stopColor='#f9f9f9' />
          <stop offset={0.302} stopColor='#e8e8e8' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.716} stopColor='#a5a5a5' />
          <stop offset={0.944} stopColor='#737373' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M387.29 189.82c-1.22 1.03-2.46 2.24-3.01 3.31-.41.8-.45 1.52.19 2.04.24.2.47.41.68.63.45.46.83.94 1.13 1.35-1.48-.43-2.4-.86-2.97-1.2-.76-.46-.9-.78-.9-.78s.04-.39.11-1.03c.22-1.98.72-6.32 1.13-8.2.35-1.62-.05-3.73-.34-5.97 0 0 3.08 2.13 2.25 3.12-.83.99-1.43 2.93-.6 3.52.84.59 4.45 1.42 2.33 3.21z'
          mask='url(#banner_svg__SVGID_200_)'
          fill='url(#banner_svg__SVGID_201_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_202_'
      gradientUnits='userSpaceOnUse'
      x1={382.421}
      y1={188.564}
      x2={387.95}
      y2={188.564}
    >
      <stop offset={0.001} stopColor='#94b9ff' />
      <stop offset={0.188} stopColor='#9abdff' />
      <stop offset={0.438} stopColor='#abc8ff' />
      <stop offset={0.723} stopColor='#c7dbff' />
      <stop offset={1} stopColor='#e9f2ff' />
    </linearGradient>
    <path
      d='M387.29 189.82c-1.22 1.03-2.46 2.24-3.01 3.31-.41.8-.45 1.52.19 2.04.24.2.47.41.68.63.45.46.83.94 1.13 1.35-1.48-.43-2.4-.86-2.97-1.2-.76-.46-.9-.78-.9-.78s.04-.39.11-1.03c.22-1.98.72-6.32 1.13-8.2.35-1.62-.05-3.73-.34-5.97 0 0 3.08 2.13 2.25 3.12-.83.99-1.43 2.93-.6 3.52.84.59 4.45 1.42 2.33 3.21z'
      fill='url(#banner_svg__SVGID_202_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_34_'
        filterUnits='userSpaceOnUse'
        x={398.3}
        y={176.55}
        width={1.4}
        height={2.21}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={398.3} y={176.55} width={1.4} height={2.21} id='banner_svg__SVGID_203_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_34_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_35_'
            filterUnits='userSpaceOnUse'
            x={398.3}
            y={176.55}
            width={1.4}
            height={2.21}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_204_'
          gradientUnits='userSpaceOnUse'
          x1={398.304}
          y1={177.652}
          x2={399.701}
          y2={177.652}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M399.66 178.57c.07.61-1.35-.46-1.35-.46.42.17.72.1.88-.23.1-.22.28-.71.52-1.33-.19.47-.12 1.41-.05 2.02z'
          mask='url(#banner_svg__SVGID_203_)'
          fill='url(#banner_svg__SVGID_204_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_205_'
      gradientUnits='userSpaceOnUse'
      x1={398.304}
      y1={177.652}
      x2={399.701}
      y2={177.652}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path
      d='M399.66 178.57c.07.61-1.35-.46-1.35-.46.42.17.72.1.88-.23.1-.22.28-.71.52-1.33-.19.47-.12 1.41-.05 2.02z'
      fill='url(#banner_svg__SVGID_205_)'
    />
    <linearGradient
      id='banner_svg__SVGID_206_'
      gradientUnits='userSpaceOnUse'
      x1={383.284}
      y1={197.559}
      x2={392.775}
      y2={197.559}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#002294' />
    </linearGradient>
    <path
      d='M392.78 197.58s-4.19 1.25-6.54.76c-1.31-.28-2.17.28-2.69.83-.02-.08-.05-.18-.07-.27-.31-1.29-.16-2.96-.16-2.96.57.35 1.49.77 2.97 1.2.45.13.95.26 1.5.39 1.81.44 3.53.34 4.99.05z'
      fill='url(#banner_svg__SVGID_206_)'
    />
    <path
      className='banner_svg__st187'
      d='M393.12 164.2s-1.67.33-.92 2.68c.76 2.36 1.52 3.48.36 4.31-1.16.83-.6 1.49-.28 2.25.72 1.73.98 4.53-2.83 4.23-3.81-.3-7.09-2.32-6.01-5.13.6-1.56 3.05-2.25 1.77-3.4-.66-.6-.68-2.39 0-4.29.87-2.46 2.31-4.11 4.94-3.71 2.62.41 2.97 3.06 2.97 3.06z'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_36_'
        filterUnits='userSpaceOnUse'
        x={138.09}
        y={62.8}
        width={2.39}
        height={58.41}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={138.09} y={62.8} width={2.39} height={58.41} id='banner_svg__SVGID_207_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_36_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_37_'
            filterUnits='userSpaceOnUse'
            x={138.09}
            y={62.8}
            width={2.39}
            height={58.41}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_208_'
          gradientUnits='userSpaceOnUse'
          x1={138.088}
          y1={92}
          x2={140.475}
          y2={92}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M138.09 62.8s.82 30.12 1.19 58.41c0 0 .84-45.57 1.19-58.41h-2.38z'
          mask='url(#banner_svg__SVGID_207_)'
          fill='url(#banner_svg__SVGID_208_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_209_'
      gradientUnits='userSpaceOnUse'
      x1={138.088}
      y1={92}
      x2={140.475}
      y2={92}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M138.09 62.8s.82 30.12 1.19 58.41c0 0 .84-45.57 1.19-58.41h-2.38z' fill='url(#banner_svg__SVGID_209_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_38_'
        filterUnits='userSpaceOnUse'
        x={130.45}
        y={46.97}
        width={17.67}
        height={17.67}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={130.45} y={46.97} width={17.67} height={17.67} id='banner_svg__SVGID_210_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_38_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_39_'
            filterUnits='userSpaceOnUse'
            x={130.45}
            y={46.97}
            width={17.67}
            height={17.67}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_211_'
          gradientUnits='userSpaceOnUse'
          x1={148.114}
          y1={55.805}
          x2={130.449}
          y2={55.805}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={139.28}
          cy={55.8}
          r={8.83}
          mask='url(#banner_svg__SVGID_210_)'
          fill='url(#banner_svg__SVGID_211_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_212_'
      gradientUnits='userSpaceOnUse'
      x1={148.114}
      y1={55.805}
      x2={130.449}
      y2={55.805}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={139.28} cy={55.8} r={8.83} fill='url(#banner_svg__SVGID_212_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_40_'
        filterUnits='userSpaceOnUse'
        x={298.01}
        y={238.63}
        width={2.39}
        height={132.64}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={298.01} y={238.63} width={2.39} height={132.64} id='banner_svg__SVGID_213_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_40_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_41_'
            filterUnits='userSpaceOnUse'
            x={298.01}
            y={238.63}
            width={2.39}
            height={132.64}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_214_'
          gradientUnits='userSpaceOnUse'
          x1={298.009}
          y1={304.955}
          x2={300.395}
          y2={304.955}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M298.01 238.63s.82 68.39 1.19 132.64c0 0 .84-103.49 1.19-132.64h-2.38z'
          mask='url(#banner_svg__SVGID_213_)'
          fill='url(#banner_svg__SVGID_214_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_215_'
      gradientUnits='userSpaceOnUse'
      x1={298.009}
      y1={304.955}
      x2={300.395}
      y2={304.955}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path
      d='M298.01 238.63s.82 68.39 1.19 132.64c0 0 .84-103.49 1.19-132.64h-2.38z'
      fill='url(#banner_svg__SVGID_215_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_42_'
        filterUnits='userSpaceOnUse'
        x={290.37}
        y={222.81}
        width={17.67}
        height={17.67}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={290.37} y={222.81} width={17.67} height={17.67} id='banner_svg__SVGID_216_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_42_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_43_'
            filterUnits='userSpaceOnUse'
            x={290.37}
            y={222.81}
            width={17.67}
            height={17.67}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_217_'
          gradientUnits='userSpaceOnUse'
          x1={308.035}
          y1={231.644}
          x2={290.369}
          y2={231.644}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={299.2}
          cy={231.64}
          r={8.83}
          mask='url(#banner_svg__SVGID_216_)'
          fill='url(#banner_svg__SVGID_217_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_218_'
      gradientUnits='userSpaceOnUse'
      x1={308.035}
      y1={231.644}
      x2={290.369}
      y2={231.644}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={299.2} cy={231.64} r={8.83} fill='url(#banner_svg__SVGID_218_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_44_'
        filterUnits='userSpaceOnUse'
        x={333.65}
        y={83.29}
        width={2.39}
        height={93.02}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={333.65} y={83.29} width={2.39} height={93.02} id='banner_svg__SVGID_219_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_44_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_45_'
            filterUnits='userSpaceOnUse'
            x={333.65}
            y={83.29}
            width={2.39}
            height={93.02}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_220_'
          gradientUnits='userSpaceOnUse'
          x1={333.655}
          y1={129.795}
          x2={336.042}
          y2={129.795}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M333.65 83.29s.82 47.96 1.19 93.02c0 0 .84-72.58 1.19-93.02h-2.38z'
          mask='url(#banner_svg__SVGID_219_)'
          fill='url(#banner_svg__SVGID_220_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_221_'
      gradientUnits='userSpaceOnUse'
      x1={333.655}
      y1={129.795}
      x2={336.042}
      y2={129.795}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M333.65 83.29s.82 47.96 1.19 93.02c0 0 .84-72.58 1.19-93.02h-2.38z' fill='url(#banner_svg__SVGID_221_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_46_'
        filterUnits='userSpaceOnUse'
        x={326.02}
        y={67.46}
        width={17.67}
        height={17.67}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={326.02} y={67.46} width={17.67} height={17.67} id='banner_svg__SVGID_222_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_46_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_47_'
            filterUnits='userSpaceOnUse'
            x={326.02}
            y={67.46}
            width={17.67}
            height={17.67}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_223_'
          gradientUnits='userSpaceOnUse'
          x1={343.681}
          y1={76.295}
          x2={326.016}
          y2={76.295}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={334.85}
          cy={76.29}
          r={8.83}
          mask='url(#banner_svg__SVGID_222_)'
          fill='url(#banner_svg__SVGID_223_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_224_'
      gradientUnits='userSpaceOnUse'
      x1={343.681}
      y1={76.295}
      x2={326.016}
      y2={76.295}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={334.85} cy={76.29} r={8.83} fill='url(#banner_svg__SVGID_224_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_48_'
        filterUnits='userSpaceOnUse'
        x={83.33}
        y={265.63}
        width={2.39}
        height={58.41}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={83.33} y={265.63} width={2.39} height={58.41} id='banner_svg__SVGID_225_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_48_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_49_'
            filterUnits='userSpaceOnUse'
            x={83.33}
            y={265.63}
            width={2.39}
            height={58.41}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_226_'
          gradientUnits='userSpaceOnUse'
          x1={83.326}
          y1={294.833}
          x2={85.713}
          y2={294.833}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M83.33 265.63s.82 30.12 1.19 58.41c0 0 .84-45.57 1.19-58.41h-2.38z'
          mask='url(#banner_svg__SVGID_225_)'
          fill='url(#banner_svg__SVGID_226_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_227_'
      gradientUnits='userSpaceOnUse'
      x1={83.326}
      y1={294.833}
      x2={85.713}
      y2={294.833}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M83.33 265.63s.82 30.12 1.19 58.41c0 0 .84-45.57 1.19-58.41h-2.38z' fill='url(#banner_svg__SVGID_227_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_50_'
        filterUnits='userSpaceOnUse'
        x={75.69}
        y={249.8}
        width={17.67}
        height={17.67}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={75.69} y={249.8} width={17.67} height={17.67} id='banner_svg__SVGID_228_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_50_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_51_'
            filterUnits='userSpaceOnUse'
            x={75.69}
            y={249.8}
            width={17.67}
            height={17.67}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_229_'
          gradientUnits='userSpaceOnUse'
          x1={93.352}
          y1={258.638}
          x2={75.687}
          y2={258.638}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={84.52}
          cy={258.64}
          r={8.83}
          mask='url(#banner_svg__SVGID_228_)'
          fill='url(#banner_svg__SVGID_229_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_230_'
      gradientUnits='userSpaceOnUse'
      x1={93.352}
      y1={258.638}
      x2={75.687}
      y2={258.638}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={84.52} cy={258.64} r={8.83} fill='url(#banner_svg__SVGID_230_)' />
    <path className='banner_svg__st17' d='M175.9 108.22v91.22l138.32-79.82V17L264.8 45.49l-9.92 17.13z' />
    <linearGradient
      id='banner_svg__SVGID_231_'
      gradientUnits='userSpaceOnUse'
      x1={166.026}
      y1={150.978}
      x2={175.903}
      y2={150.978}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_231_)' d='M175.9 199.44l-9.87-5.69v-91.24l9.87 5.71z' />
    <linearGradient
      id='banner_svg__SVGID_232_'
      gradientUnits='userSpaceOnUse'
      x1={245.043}
      y1={51.203}
      x2={264.796}
      y2={51.203}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_232_)' d='M245.04 56.89l9.88-17.1 9.88 5.7-9.92 17.13z' />
    <path className='banner_svg__st17' d='M254.92 39.79l49.42-28.51 9.88 5.72-49.42 28.49z' />
    <linearGradient
      id='banner_svg__SVGID_233_'
      gradientUnits='userSpaceOnUse'
      x1={166.031}
      y1={75.437}
      x2={259.857}
      y2={75.437}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_233_)' d='M249.98 48.34l-83.95 48.47 9.88 5.72 83.95-48.51z' />
    <linearGradient
      id='banner_svg__SVGID_234_'
      gradientUnits='userSpaceOnUse'
      x1={166.031}
      y1={102.511}
      x2={175.908}
      y2={102.511}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_234_)' d='M166.03 96.81v5.7l9.87 5.71.01-5.69z' />
    <linearGradient
      id='banner_svg__SVGID_235_'
      gradientUnits='userSpaceOnUse'
      x1={259.857}
      y1={81.116}
      x2={175.908}
      y2={81.116}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_235_)' d='M175.91 102.53v5.69l78.97-45.6 4.98-8.6z' />
    <linearGradient
      id='banner_svg__SVGID_236_'
      gradientUnits='userSpaceOnUse'
      x1={254.875}
      y1={39.811}
      x2={314.219}
      y2={39.811}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_236_)' d='M254.88 62.62l59.29-34.21.05-11.41-49.42 28.49z' />
    <g>
      <path
        className='banner_svg__st17'
        d='M274.67 46.58c-.38 0-.75-.2-.95-.55-.3-.52-.12-1.19.4-1.5l19.8-11.43c.52-.3 1.19-.12 1.5.4.3.52.12 1.19-.4 1.5l-19.8 11.43c-.18.11-.36.15-.55.15z'
      />
    </g>
    <linearGradient
      id='banner_svg__SVGID_237_'
      gradientUnits='userSpaceOnUse'
      x1={191.877}
      y1={131.733}
      x2={212.798}
      y2={131.733}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M212.8 123.43c-.09-.02-.18-.04-.27-.03-.11.01-.22.04-.32.1l-.12.07-18.77 10.84c-.53.31-.86.87-.86 1.48v3.67c0 .04 0 .08.01.12.03.18.11.34.24.45.07.07.16.12.25.16l-.51-.19-.06-.02a.794.794 0 01-.5-.74v-3.67c0-.61.33-1.17.86-1.48l18.89-10.91c.2-.11.42-.13.61-.06l.55.21z'
      fill='url(#banner_svg__SVGID_237_)'
    />
    <linearGradient
      id='banner_svg__SVGID_238_'
      gradientUnits='userSpaceOnUse'
      x1={192.459}
      y1={131.871}
      x2={213.326}
      y2={131.871}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M213.33 124.14v3.62c0 .69-.37 1.33-.96 1.67l-18.72 10.81c-.22.12-.45.13-.66.06l-.03-.01a.792.792 0 01-.49-.61c-.01-.04-.01-.08-.01-.12v-3.67c0-.61.33-1.18.86-1.48l18.77-10.84.12-.07a.748.748 0 01.59-.07l.06.02c.27.11.47.36.47.69z'
      fill='url(#banner_svg__SVGID_238_)'
    />
    <linearGradient
      id='banner_svg__SVGID_239_'
      gradientUnits='userSpaceOnUse'
      x1={191.877}
      y1={138.308}
      x2={220.701}
      y2={138.308}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M220.7 127.94a.576.576 0 00-.29-.05c-.11 0-.22.03-.33.09l-.14.08-26.61 15.09c-.53.31-.86.87-.86 1.48v3.67c0 .21.08.39.2.52.06.07.14.13.22.17l-.5-.23-.03-.01a.78.78 0 01-.46-.71v-3.67c0-.61.33-1.17.86-1.48l26.76-15.17c.22-.12.46-.12.67-.02l.51.24z'
      fill='url(#banner_svg__SVGID_239_)'
    />
    <linearGradient
      id='banner_svg__SVGID_240_'
      gradientUnits='userSpaceOnUse'
      x1={192.459}
      y1={138.488}
      x2={221.148}
      y2={138.488}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M221.15 128.6l-.01 3.78c0 .62-.33 1.18-.87 1.49l-26.63 15.12c-.25.15-.53.13-.76.01h-.01a.868.868 0 01-.22-.17.8.8 0 01-.2-.52v-3.67c0-.61.33-1.18.86-1.48l26.61-15.09.14-.08c.11-.06.22-.09.33-.09.1 0 .2.01.29.05l.04.02c.26.1.43.34.43.63z'
      fill='url(#banner_svg__SVGID_240_)'
    />
    <linearGradient
      id='banner_svg__SVGID_241_'
      gradientUnits='userSpaceOnUse'
      x1={191.877}
      y1={144.376}
      x2={231.14}
      y2={144.376}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M231.14 130.97a.66.66 0 00-.27-.06c-.13 0-.26.03-.38.1l-.1.06-37.07 21.26c-.53.31-.86.87-.86 1.48V157.48c0 .19.06.35.17.48.05.07.12.13.19.18l-.49-.25a.77.77 0 01-.45-.71v-3.67c0-.61.33-1.18.86-1.48l37.17-21.32c.25-.14.53-.12.74.01l.49.25z'
      fill='url(#banner_svg__SVGID_241_)'
    />
    <linearGradient
      id='banner_svg__SVGID_242_'
      gradientUnits='userSpaceOnUse'
      x1={192.459}
      y1={144.591}
      x2={231.588}
      y2={144.591}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M231.59 131.64v3.73c0 .63-.34 1.22-.89 1.54l-37.06 21.25c-.23.13-.48.14-.69.05l-.02-.01-.09-.05-.01-.01a.813.813 0 01-.19-.18.777.777 0 01-.17-.48V153.81c0-.61.33-1.18.86-1.48l37.07-21.26.1-.06c.12-.07.25-.1.38-.1.09 0 .19.02.27.06l.04.02c.23.11.4.35.4.65z'
      fill='url(#banner_svg__SVGID_242_)'
    />
    <linearGradient
      id='banner_svg__SVGID_243_'
      gradientUnits='userSpaceOnUse'
      x1={247.563}
      y1={98.123}
      x2={304.223}
      y2={98.123}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M304.22 61.02c-.29-.07-.6-.04-.9.09-.05.02-.1.05-.15.08l-.57.33-51.56 29.78c-.99.57-1.6 1.63-1.6 2.78v40.06c0 .49.18.91.46 1.24.19.22.43.4.7.52l-1.53-.51a1.95 1.95 0 01-1.18-.81c-.2-.3-.32-.66-.32-1.06V93.45c0-1.15.61-2.21 1.6-2.78l52.14-30.11c.42-.24.89-.25 1.28-.09l.02.01 1.61.54z'
      fill='url(#banner_svg__SVGID_243_)'
    />
    <linearGradient
      id='banner_svg__SVGID_244_'
      gradientUnits='userSpaceOnUse'
      x1={249.432}
      y1={98.512}
      x2={305.385}
      y2={98.512}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M305.38 62.46v40.94c0 1.07-.57 2.05-1.49 2.59l-51.6 29.79c-.56.32-1.18.33-1.7.11h-.01a1.89 1.89 0 01-.7-.52 1.89 1.89 0 01-.46-1.24V94.07c0-1.14.61-2.2 1.6-2.78l51.56-29.78.57-.33c.05-.03.1-.05.15-.08.3-.13.61-.15.9-.09l.3.1c.52.24.88.73.88 1.35z'
      fill='url(#banner_svg__SVGID_244_)'
    />
    <linearGradient
      id='banner_svg__SVGID_245_'
      gradientUnits='userSpaceOnUse'
      x1={260.622}
      y1={84.442}
      x2={260.635}
      y2={120.946}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_245_)' d='M257.82 80.91l5.6-3.23.01 45.22-5.59 3.23z' />
    <linearGradient
      id='banner_svg__SVGID_246_'
      gradientUnits='userSpaceOnUse'
      x1={271.89}
      y1={69.786}
      x2={271.71}
      y2={117.308}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_246_)' d='M269.02 61.23l5.59-3.13.01 58.35-5.6 3.23z' />
    <linearGradient
      id='banner_svg__SVGID_247_'
      gradientUnits='userSpaceOnUse'
      x1={282.673}
      y1={90.114}
      x2={283.541}
      y2={112.091}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_247_)' d='M280.21 87.36l5.59-3.23.01 25.85-5.59 3.23z' />
    <linearGradient
      id='banner_svg__SVGID_248_'
      gradientUnits='userSpaceOnUse'
      x1={294.026}
      y1={92.765}
      x2={294.488}
      y2={104.349}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_248_)' d='M291.4 90.6l5.59-3.23.01 16.15-5.6 3.23z' />
    <g>
      <defs>
        <filter
          id='banner_svg__Adobe_OpacityMaskFilter_52_'
          filterUnits='userSpaceOnUse'
          x={186.18}
          y={34.5}
          width={51.69}
          height={56.24}
        >
          <feFlood result='back' floodColor='#fff' floodOpacity={1} />
          <feBlend in='SourceGraphic' in2='back' />
        </filter>
      </defs>
      <mask maskUnits='userSpaceOnUse' x={186.18} y={34.5} width={51.69} height={56.24} id='banner_svg__SVGID_249_'>
        <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_52_)'>
          <defs>
            <filter
              id='banner_svg__Adobe_OpacityMaskFilter_53_'
              filterUnits='userSpaceOnUse'
              x={186.18}
              y={34.5}
              width={51.69}
              height={56.24}
            >
              <feFlood result='back' floodColor='#fff' floodOpacity={1} />
              <feBlend in='SourceGraphic' in2='back' />
            </filter>
          </defs>
          <linearGradient
            id='banner_svg__SVGID_250_'
            gradientUnits='userSpaceOnUse'
            x1={1579.057}
            y1={62.62}
            x2={1630.748}
            y2={62.62}
            gradientTransform='translate(-1392.879)'
          >
            <stop offset={0} stopColor='#666' />
            <stop offset={0.056} stopColor='#737373' />
            <stop offset={0.284} stopColor='#a5a5a5' />
            <stop offset={0.5} stopColor='#ccc' />
            <stop offset={0.698} stopColor='#e8e8e8' />
            <stop offset={0.87} stopColor='#f9f9f9' />
            <stop offset={0.999} stopColor='#fff' />
          </linearGradient>
          <path
            d='M186.37 90.74c-.19-3.66-.96-22.06 2.93-24.3.57-.33 1.18-.39 1.78-.19 1.95.65 3.42 4.07 5.13 8.03 1.34 3.12 3.01 7.01 4.39 7.47.13.04.3.07.55-.07 1.97-1.14 2.23-10.2 2.43-17.48.3-10.77.67-17.42 3.29-18.93.57-.33 1.17-.37 1.75-.14 2.09.85 3.42 5.27 5.43 12.42 1.55 5.51 3.47 12.37 5.46 13.17.17.07.4.12.75-.09 2.7-1.56 2.52-11.1 2.37-18.77-.18-9.63-.17-15.61 2.4-17.1.57-.33 1.19-.37 1.75-.1 2.08.99 2.99 6.16 4.31 14.5.79 5.01 1.98 12.57 3.35 13.22.02.01.05.02.16-.04 1.07-.62 2.17-5.61 1.24-14.77l1.7-.17c.24 2.4 1.28 14.48-2.09 16.42-.57.33-1.19.37-1.74.1-2.08-.99-2.99-6.16-4.3-14.5-.79-5.01-1.98-12.58-3.36-13.23-.02-.01-.05-.02-.16.04-1.82 1.06-1.67 9.11-1.55 15.59.18 9.37.35 18.21-3.22 20.28-.73.42-1.51.49-2.25.19-2.76-1.12-4.56-7.52-6.46-14.29-1.34-4.78-3.01-10.73-4.43-11.31-.05-.02-.12-.05-.26.04-1.98 1.14-2.23 10.21-2.44 17.5-.3 10.76-.67 17.4-3.28 18.91-.62.36-1.29.43-1.94.21-2.11-.7-3.72-4.45-5.42-8.41-1.28-2.98-2.87-6.68-4.1-7.09-.11-.04-.22-.04-.39.05-2.19 1.27-2.54 14.06-2.08 22.73l-1.7.11z'
            mask='url(#banner_svg__SVGID_249_)'
            fill='url(#banner_svg__SVGID_250_)'
          />
        </g>
      </mask>
      <linearGradient
        id='banner_svg__SVGID_251_'
        gradientUnits='userSpaceOnUse'
        x1={1579.057}
        y1={62.62}
        x2={1630.748}
        y2={62.62}
        gradientTransform='translate(-1392.879)'
      >
        <stop offset={0} stopColor='#e9f2ff' />
        <stop offset={0.277} stopColor='#c7dbff' />
        <stop offset={0.562} stopColor='#abc8ff' />
        <stop offset={0.812} stopColor='#9abdff' />
        <stop offset={0.999} stopColor='#94b9ff' />
      </linearGradient>
      <path
        d='M186.37 90.74c-.19-3.66-.96-22.06 2.93-24.3.57-.33 1.18-.39 1.78-.19 1.95.65 3.42 4.07 5.13 8.03 1.34 3.12 3.01 7.01 4.39 7.47.13.04.3.07.55-.07 1.97-1.14 2.23-10.2 2.43-17.48.3-10.77.67-17.42 3.29-18.93.57-.33 1.17-.37 1.75-.14 2.09.85 3.42 5.27 5.43 12.42 1.55 5.51 3.47 12.37 5.46 13.17.17.07.4.12.75-.09 2.7-1.56 2.52-11.1 2.37-18.77-.18-9.63-.17-15.61 2.4-17.1.57-.33 1.19-.37 1.75-.1 2.08.99 2.99 6.16 4.31 14.5.79 5.01 1.98 12.57 3.35 13.22.02.01.05.02.16-.04 1.07-.62 2.17-5.61 1.24-14.77l1.7-.17c.24 2.4 1.28 14.48-2.09 16.42-.57.33-1.19.37-1.74.1-2.08-.99-2.99-6.16-4.3-14.5-.79-5.01-1.98-12.58-3.36-13.23-.02-.01-.05-.02-.16.04-1.82 1.06-1.67 9.11-1.55 15.59.18 9.37.35 18.21-3.22 20.28-.73.42-1.51.49-2.25.19-2.76-1.12-4.56-7.52-6.46-14.29-1.34-4.78-3.01-10.73-4.43-11.31-.05-.02-.12-.05-.26.04-1.98 1.14-2.23 10.21-2.44 17.5-.3 10.76-.67 17.4-3.28 18.91-.62.36-1.29.43-1.94.21-2.11-.7-3.72-4.45-5.42-8.41-1.28-2.98-2.87-6.68-4.1-7.09-.11-.04-.22-.04-.39.05-2.19 1.27-2.54 14.06-2.08 22.73l-1.7.11z'
        fill='url(#banner_svg__SVGID_251_)'
      />
    </g>
  </svg>
)

export default SvgBanner
