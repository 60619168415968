import React from 'react'

const SvgEnterpriseInterconnectionFeature1 = props => (
  <svg
    id='enterprise-interconnection-feature1_svg__\u56FE\u5C42_1'
    x={0}
    y={0}
    viewBox='0 0 50 50'
    xmlSpace='preserve'
    {...props}
  >
    <style>{'.enterprise-interconnection-feature1_svg__st1{fill:#fd5c1f}'}</style>
    <g id='enterprise-interconnection-feature1_svg__ic_\u591A\u79CD\u63A5\u5165\u6A21\u5F0F'>
      <path
        id='enterprise-interconnection-feature1_svg__Oval-7_8_'
        d='M25 49C11.75 49 1 38.25 1 25S11.75 1 25 1s24 10.75 24 24-10.75 24-24 24zm0-2c12.15 0 22-9.85 22-22S37.15 3 25 3 3 12.85 3 25s9.85 22 22 22z'
        fill='#334966'
      />
      <circle
        id='enterprise-interconnection-feature1_svg__Oval-22_1_'
        className='enterprise-interconnection-feature1_svg__st1'
        cx={25}
        cy={25}
        r={7}
      />
      <circle
        id='enterprise-interconnection-feature1_svg__\u692D\u5706\u5F62'
        cx={25}
        cy={25}
        r={15}
        fill='none'
        stroke='#334966'
        strokeWidth={2}
      />
      <circle
        id='enterprise-interconnection-feature1_svg__\u692D\u5706\u5F62_1_'
        className='enterprise-interconnection-feature1_svg__st1'
        cx={25}
        cy={10}
        r={3}
      />
      <circle
        id='enterprise-interconnection-feature1_svg__\u692D\u5706\u5F62_2_'
        className='enterprise-interconnection-feature1_svg__st1'
        cx={38}
        cy={32}
        r={3}
      />
      <circle
        id='enterprise-interconnection-feature1_svg__\u692D\u5706\u5F62_3_'
        className='enterprise-interconnection-feature1_svg__st1'
        cx={12}
        cy={32}
        r={3}
      />
    </g>
  </svg>
)

export default SvgEnterpriseInterconnectionFeature1
