import React from 'react'

const SvgEnterpriseInterconnectionFeature2 = props => (
  <svg
    id='enterprise-interconnection-feature2_svg__\u56FE\u5C42_1'
    x={0}
    y={0}
    viewBox='0 0 50 50'
    xmlSpace='preserve'
    {...props}
  >
    <style>{'.enterprise-interconnection-feature2_svg__st2{fill:#334966}'}</style>
    <g id='enterprise-interconnection-feature2_svg__\u9AD8\u5EA6\u517C\u5BB9-MySQL-\u8BED\u6CD5-48x48'>
      <circle id='enterprise-interconnection-feature2_svg__Oval-48' cx={19} cy={19} r={10} fill='#fd5c1f' />
      <path
        id='enterprise-interconnection-feature2_svg__Fill-1_6_'
        d='M42.05 39.8c-.81-.63-1.69-2.67-5.23-6.09.26-.03.52-.06.73-.08 1.76-.19 4.54-.29 4.84-.8.06-.12.1-.24.1-.36.01-.12-.01-.26-.05-.43-.66-1.61-6.3-4.01-8.67-4.11-.22-.01-.72-1.09-1.02-1.74-.55-1.19-1.31-2.82-2.63-4.54-2.28-3.09-5.63-5.11-9.48-5.69-.74-.07-1.32-.49-1.88-.89-.77-.55-1.93-1.38-3.14-.22-.95.91-.76 2.62.02 3.65l.07.08c.31.32.6.67.85 1.04.04.13.08.27.12.42.38 1.35.96 3.4 2.56 4.89.04.04-.06.49-.16.73-.34.87-.85 2.23-.6 4.42.26 2.21 1.1 4.51 2.24 4.51.63 0 1.37-1.36 1.9-2.83.17.35.36.67.59 1 .82 1.19 2.72 3.21 3.8 4.19'
        fill='none'
        stroke='#334966'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <path
        id='enterprise-interconnection-feature2_svg__Fill-1_5_'
        className='enterprise-interconnection-feature2_svg__st2'
        d='M25 1C11.74 1 1 11.74 1 25s10.74 24 24 24 24-10.74 24-24S38.26 1 25 1m0 2c12.13 0 22 9.87 22 22s-9.87 22-22 22S3 37.13 3 25 12.87 3 25 3'
      />
      <circle
        id='enterprise-interconnection-feature2_svg__Oval-47'
        className='enterprise-interconnection-feature2_svg__st2'
        cx={22}
        cy={20}
        r={1}
      />
    </g>
  </svg>
)

export default SvgEnterpriseInterconnectionFeature2
