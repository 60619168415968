import React from 'react'

const SvgEnterpriseInterconnectionFeature3 = props => (
  <svg
    id='enterprise-interconnection-feature3_svg__\u56FE\u5C42_1'
    x={0}
    y={0}
    viewBox='0 0 50 50'
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.enterprise-interconnection-feature3_svg__st0,.enterprise-interconnection-feature3_svg__st2{fill:none;stroke:#334966;stroke-width:2}.enterprise-interconnection-feature3_svg__st2{stroke-linecap:square}'
      }
    </style>
    <g
      id='enterprise-interconnection-feature3_svg__\u817E\u8BAF\u4E91-\u5929\u67A2_1_'
      transform='translate(-295 -858)'
    >
      <g id='enterprise-interconnection-feature3_svg__features_1_' transform='translate(0 718)'>
        <g id='enterprise-interconnection-feature3_svg__features-grid_1_' transform='translate(143 140)'>
          <g id='enterprise-interconnection-feature3_svg__feature_1_'>
            <g id='enterprise-interconnection-feature3_svg__icon' transform='translate(153)'>
              <g id='enterprise-interconnection-feature3_svg__features_icon_1'>
                <path
                  id='enterprise-interconnection-feature3_svg__Polygon_2_'
                  className='enterprise-interconnection-feature3_svg__st0'
                  d='M47.5 25L36 45H13L1.5 25 13 5h23z'
                />
                <path
                  id='enterprise-interconnection-feature3_svg__Rectangle_6_'
                  fill='#fd5c1f'
                  d='M19.5 23.49l5-3.51h10v14.06h-10l-5-3.52z'
                />
                <path
                  id='enterprise-interconnection-feature3_svg__Rectangle_5_'
                  className='enterprise-interconnection-feature3_svg__st0'
                  d='M17.5 21.5v6.99l4.98 3.53H32.5V17.97H22.48L17.5 21.5z'
                />
                <path
                  id='enterprise-interconnection-feature3_svg__Line'
                  className='enterprise-interconnection-feature3_svg__st2'
                  d='M16.5 25h-4.93'
                />
                <path
                  id='enterprise-interconnection-feature3_svg__Line_1_'
                  className='enterprise-interconnection-feature3_svg__st2'
                  d='M36.5 22.99h-3.94'
                />
                <path
                  id='enterprise-interconnection-feature3_svg__Line_2_'
                  className='enterprise-interconnection-feature3_svg__st2'
                  d='M36.5 27.01h-3.94'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgEnterpriseInterconnectionFeature3
