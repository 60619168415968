import styled, { css } from 'styled-components'
import { Wrapper, Flex } from '@raysync/common/components'

/* 第二屏 */
export const Section = styled(Wrapper)`
  padding: 60px 0 0 0;
`
export const SectionDesc = styled.p`
  ${({ theme: { color, typography } }) => css`
    color: ${color.text};
    line-height: 24px;
    font-size: ${typography.textSmall};
    padding-top: ${p => p.top + 'px'};
    padding-bottom: ${p => p.bottom + 'px'};
  `}
`

/* 第三屏 */
export const FeatureSection = styled.div`
  ${({ theme: { color } }) => css`
    padding: 60px 0 78px 0;
    background-color: ${color.panel};
    .jJuBUt {
      min-height: 120px;
    }
  `}
`

/* 第四屏 */
export const ArchSection = styled.div`
  padding: 63px 0 90px 0;
  svg {
    display: block;
    width: 100%;
    margin: 46px auto 0;
  }
`

/* 第五屏 */
export const ApplicationSection = styled.div`
  ${({ theme: { color } }) => css`
    padding: 65px 0 90px 0;
    background-color: ${color.panel};
  `}
`
export const ApplicationContainer = styled(Flex)`
  margin-top: 41px;
  justify-content: space-between;
`
export const ApplicationItem = styled.div`
  ${({ theme: { color } }) => css`
    width: 270px;
    height: 240px;
    background-color: ${color.base};
    box-shadow: 0px 0px 12px 0px rgba(228, 232, 234, 1);
    svg {
      width: 150px;
      height: 100px;
      display: block;
      margin: 45px auto 50px;
    }
    p {
      text-align: center;
      color: ${color.text};
    }
  `}
`
